/*Bootstrap class*/
.list-unstyled,
.list-inline,
.bg-primary,
.text-muted,
.text-primary,
.text-justify,
.initialism,
.blockquote-reverse{ }



/*content-container*/
.content-container .inner { padding: 20px 10px; }

/*standard-content*/
.standard-content p{ margin-bottom:.5em;}
.standard-content .print-instructions { margin-bottom:.5em; color:#13100d;  display:table; width:100%;  }
.standard-content .print-instructions .icon { margin-right:5px; top:3px;}

/*lists*/
ul.list,
ol.list{ margin-bottom:20px; margin-top:10px; padding-left:15px; }

ol.list {
  counter-reset: item;
}

ol.list > li {
  margin: 0;
  padding: 0 0 0 2em;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
  margin-bottom:10px;
}

ol.list > li:before {
  display: inline-block;
  width: 1em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}

.list.list-icon-aligned {
  padding-left: 50px;
}

.circle-number-list{ padding-top:10px;}
.circle-number-list > li{
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  counter-increment: item;
  margin-bottom:30px;
}
.circle-number-list > li:before {
  display: inline-block;
  width: 24px;
  height:24px;
  text-align:center;
  padding-top:2px;
  padding-right:8px;
  font-weight: bold;
  text-align: right;
  content: counter(item);
  position:absolute;
  left:-40px;
  top:-2px;
  background:#000;
  color:#fff;
  @include border-radius(12px);

}

.horizontal-list { padding-bottom: 20px; }
.horizontal-list li{ display:inline-block; float:left; padding:0 15px; border-left:1px solid #13100d;  }
.horizontal-list li:first-child { padding-left:0; border-left:none; }






label.small-text {
  font-size: 14px !important;
}
label.xs-text {
  font-size: 12px !important;
  padding-top: 3px;
}

.extented-content {
  margin-left: -20px;
  margin-right: -20px;
}

.debit-card-payment-details,
.bank-transfer-information,
.load-page-detailed-contents{ color:#737373; margin-bottom:20px; font-size:12px;  }
.bank-transfer-information h4{ font-weight:700; color:#13100d; }


@media (max-width: $screen-xs-max) {
   .standard-content .print-instructions{ display:none; }

   .circle-number-list > li,
   .horizontal-list > li{
      display:table;
    }

}
