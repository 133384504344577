/*footer*/
.footer{ background-color:#000;  margin-top:-40px; padding-top: 80px; color:#fff; z-index: 1; }
.footer a{ color:#fff; }
.footer .left-col{ width:530px;}
.footer .site-links{ margin-bottom:20px;}
.footer .site-links li{ margin-bottom:10px; list-style:none;}
.footer .site-links li a{ text-decoration:none; }
.footer .logo-set{ margin-bottom:20px;}
.footer .logo-set li{ display:inline; padding-right:40px; float:left;}
.footer .logo-set img{ width:100%;}
.footer .logo-set .logo-onesmart img{ width:145px; margin-top:7px; }
.footer .logo-set .logo-airpoints img{ width:130px; }
.footer .logo-set .logo-mastercard img{ width:70px; }

.footer .col-sm-12 > .inner { padding-top:30px; padding-bottom:30px;}
.footer .row-2 .col-sm-12{ border-bottom:1px solid #1a1a1a; margin-bottom:40px;}
.footer .border-top-line:before { border-color:#1a1a1a; }

@media (max-width: $screen-xs-max) {
    .footer{  margin-top:0; padding-top: 0; color:#fff; z-index: 1; }
    .footer .left-col{ width:100%; border-bottom:1px solid #1a1a1a; margin-bottom:20px;  }
    .footer .logo-set li{ width:50%; margin-bottom:20px; padding-right:20px;  }
    .footer .logo-set .logo-onesmart img{ width:125px; }
    .footer .logo-set .logo-airpoints img{ width:115px; }
    .footer .row-2 .col-sm-12 > .inner{ padding-top:0;}
    .footer .row-2 .col-sm-12{ border-bottom:none; margin-bottom:0;}
}