$tab-mobile-height: 80px;
$nav-highlight-color: #0099a3;

/* step-tabs */
.step-tabs-container{ background-color:#dad8d9; }
.step-tabs{ padding-top:20px; background-color:#dad8d9; }
.step-tabs li {
  padding-left: 0;
  padding-right: 0;
}
.step-tabs > li > a {
    margin-left: 0;
    margin-right: 0;
    border: 0;
    text-decoration:none;
    background-color:transparent;
    border-left:1px solid #bebcbd;
    @include border-radius(0);
    color:#000;
    padding: 20px;

    @media (max-width: $screen-xs-max) {
      min-height: $tab-mobile-height;
    }
}
.step-tabs > li:first-child > a { border-left:none; }
.step-tabs > li.active > a,
.step-tabs > li.active + li > a{ border-left:none; }

.step-tabs > li:last-child > a{ border-right:none;}
.step-tabs > li > a h4{ font-size:16px; font-weight:700; margin-bottom:0;}
.step-tabs > li.active > a,
.step-tabs > li.active > a:hover,
.step-tabs > li.active > a:focus {
  border: 0;
  background-color: white;
}

.step-tabs .glyphicon-check{ color:$brand-success; }
.step-tabs .icon-check-circle{ color:$brand-success; }

.step-tabs > li:first-child a{ padding-left:60px; }



.tab-pane {
  display:none;
  -vendor-animation-duration: 2s;
  -vendor-animation-delay: 0s;
  -vendor-animation-iteration-count: infinite;
}

.tab-content {
  background-color: white;
  padding:40px 0;
}
.tab-content .tab-content{ padding: 30px 0;}

/*black-white-tabs*/

.black-white-tabs.nav-tabs > li{ padding:0; margin-bottom:-2px;}
.black-white-tabs.nav-tabs > li > a {
  background-color: black;
  border-radius: 0;
  color: white;
  text-decoration: none;
  text-align:center;
  margin-right:1px;
  min-height: 45px;
  font-weight: bold;

  @media (max-width: $screen-xs-max) {
    font-size:12px;
    padding-left:5px;
    padding-right:5px;
    min-height: $tab-mobile-height;
    .glyphicon,
    .icon {
      margin-right: 0;
      width: 100%;
      height:20px;
      display: block;
      /*margin-bottom: 8px;*/
      background-position:center;
    }
  }

  @media (max-width: $screen-sm-max) {
    /*min-height: 67px;*/
  }
}
.black-white-tabs.nav-tabs > li > a:hover {
  background-color: $nav-highlight-color;
}

.black-white-tabs.nav-tabs > li > a .glyphicon,
.black-white-tabs.nav-tabs > li > a .icon { margin-right:10px; top:2px;}
.black-white-tabs.nav-tabs > li > a .icon-money{ font-size:13px; margin-right:15px;}
.black-white-tabs.nav-tabs > li > a .icon-group{ font-size:18px;}


.black-white-tabs.nav-tabs > li.active > a,
.black-white-tabs.nav-tabs > li.active > a:hover,
.black-white-tabs.nav-tabs > li.active > a:focus {
  color: #fff;
  background-color: $nav-highlight-color;
  border-color: $nav-highlight-color;

}


/** NAVIGATION FOR TABS*/
.tabbed-navigation-links {
    /*:before {
        padding-top: 10px;
    }*/
    .bordered-top {
        border-top: 1px solid #000;
    }

    .bordered-top.strong {
        border-top: 2px solid #000;
    }
    padding-top: 15px;
}





@media (max-width: 880px ) {
    .step-tabs > li:first-child a{ padding-left:10%; }
}


@media (max-width: $screen-xs-max) {
    .step-tabs{ padding-top:10px; }
    .step-tabs > li > a{ padding:10px;}
    .step-tabs > li > a h4{ font-size:12px;}
    .tab-content{ padding:30px 0; }
    .step-tabs > li:first-child a{ padding-left:20px; }




    /*make mobile navigation button stacked*/
    .stacked-xs{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction:column-reverse;
    }
    .stacked-xs .btn { display:block;}
    .stacked-xs .border-top-line,
    .stacked-xs .hr-double-dot{ order:1; width:100%; }
    .stacked-xs .btn { margin-left:auto!important; margin-right:auto !important;  }
    .stacked-xs .col-xs-6 { width:100%;  }

}
