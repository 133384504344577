
/*form-transfer-from-to*/
.form-transfer-from-to{ margin-top:0;}
.form-transfer-from-to .col-transfer-from,
.form-transfer-from-to .col-transfer-to{ width:45%; padding-top:20px; padding-bottom:10px; display:inline-block;}
.form-transfer-from-to .col-equal-sign{ width:10%; text-align:center; font-size:20px; font-weight:bold; display:inline-block; top:47px; }
.form-transfer-from-to .col-equal-sign .equal-sign{ top:45px; }

.form-transfer-from-to .col-curr{ width:40%; }
.form-transfer-from-to .col-curr .curr{ font-size:40px; }
.form-transfer-from-to .col-curr h2{ margin-bottom:0; }
.form-transfer-from-to .col-curr h6{ white-space:nowrap; }
.form-transfer-from-to .col-field { width:60%; padding-top:23px;   }

.form-transfer-from-to .col-field .input-group .input-group-addon { position:absolute; top:9px; padding:0; font-weight:700; font-size:21px; color:#000;} 
.form-transfer-from-to .col-field .input-group .input-group-addon.curr-sign { left:0; }
.form-transfer-from-to .col-field .input-group .input-group-addon.curr{ right:-8px; width:47px; font-size:14px; top:15px;}

.form-transfer-from-to .col-field .form-control{ font-size: 34px; line-height: 1; font-weight:700; color:#000; padding-left:23px; padding-right:38px; text-align:right; padding-bottom:16px; }
.form-transfer-from-to .col-field .form-control.long-currency-display{ padding-bottom:0; }
.form-transfer-from-to .col-notes{ width:100%; color:#888; font-size:12px;}
.form-transfer-from-to .item-transfer-from-to:after{
    content: "";
    display: block;
    border-top: #ddd solid 1px;
    width: 100%;
    float: left;
}
.form-transfer-from-to .item-transfer-from-to--th h6{ font-weight:700; font-size:14px;}
.form-transfer-from-to .item-transfer-from-to .remove-container{ position:absolute; right:5px; top:15px; color:$brand-primary; cursor:pointer; }


/*form-transfer-from-to.details*/
.form-transfer-from-to.details .col-amount{font-weight:700; font-size:34px; padding-top:19px;}
.form-transfer-from-to.details .col-amount .curr-sign{ font-size:21px;}
.form-transfer-from-to.details .col-amount .curr{ font-size:14px;}
.form-transfer-from-to.details .col-amount .decimal{ font-size:18px;}
.form-transfer-from-to.details .col-curr{ width:100px; }


@media (max-width: $screen-xs-max) {
    .form-transfer-from-to .col-transfer-from,
    .form-transfer-from-to .col-transfer-to{ padding-top:10px;}
    .form-transfer-from-to .col-curr,
    .form-transfer-from-to .col-field{ width:100%; }
    .form-transfer-from-to .col-field{ padding-top:10px;}
    .form-transfer-from-to .col-field .input-group .input-group-addon {font-size:16px; top:12px;}
    .form-transfer-from-to .col-field .form-control{ font-size:18px; padding-left:16px; padding-right:34px; padding-bottom:6px;  }
    .form-transfer-from-to .col-equal-sign{ top:75px;}
    .form-transfer-from-to .col-field .input-group .input-group-addon.curr{ width:47px;}
    .form-transfer-from-to .item-transfer-from-to .remove-container{ top:10px; }
    .form-transfer-from-to .col-curr h6{ white-space:normal; height:30px; }



    /*form-transfer-from-to.details*/
    .form-transfer-from-to.details .col-amount{ font-size:18px;  padding-top:0; width:100%; }
    .form-transfer-from-to.details .col-amount .curr-sign{ font-size:14px; }
    .form-transfer-from-to.details .col-equal-sign{ top:55px; }


}
