// t = Theme. Colours only.
// c = Component. Look of the element when black & white.

// Only use one type (t, c, etc) of placeholder per element / state.
// Each type of mixin should be solely responsible for a particular property.

// Variables
$brandPrimaryColor: #00677e;
$brandPrimaryColorLighter: #0099a3;
$brandPrimaryColorDarker: #003D4B;
$textColor: #06161f;

// Components
@mixin c-brochure-button-solid
{
    display: inline-block;
    padding-left: 35px;
    padding-right: 35px;

    text-align: center;
    text-decoration: none;
}
@mixin c-brochure-button-solid-large-rounded
{
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 8px;
    padding-left: 35px;
    padding-right: 35px;

    border-radius: 22px;
    font-size: 18px;
    text-align: center;
    text-decoration: none;

    @media screen and (max-width: 767px)
    {
        padding-left: 30px;
        padding-right: 30px;

        font-size: 16px;
    }
}

@mixin c-brochure-section
{
    padding: 60px 0;

    @media screen and (max-width: 767px)
    {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@mixin c-brochure-heading-2
{
    padding-bottom: 40px;
    margin: 0 0 40px;

    font-size: 34px;
    font-weight: 300;
    text-align: center;
    background: url("../images/brochure-divider.png") no-repeat center bottom;

    @media screen and (max-width: 767px)
    {
        padding-bottom: 20px;
        margin-bottom: 20px;

        font-size: 21px;
    }
}
@mixin c-brochure-heading-3
{
    margin: 0 0 16px;

    font-size: 25px;
    font-weight: 700;

    @media screen and (max-width: 767px)
    {
        font-size: 21px;
    }
}
@mixin c-brochure-heading-4
{
    padding-bottom: 25px;
    margin-bottom: 25px;

    font-size: 21px;
    font-weight: 300;
    text-align: center;
    background: url("../../public/images/divider-small.png") no-repeat 50% bottom;
}

@mixin c-brochure-list-item-with-padding
{
    position: relative;
    display: block;
    padding-left: 60px;
    margin-bottom: 27px;

    font-size: 18px;

    @media screen and (max-width: 767px)
    {
        padding-left: 40px;

        font-size: 14px;
    }
}
@mixin c-brochure-list-item-icon
{
    position: absolute;
    top: 5px;
    left: 0;

    width: 30px;
    height: 25px;

    content: "";
    background-position: 0 0;
    background-repeat: no-repeat;

    @media screen and (max-width: 767px)
    {
        top: 0;

        width: 20px;
        height: 20px;

        background-size: contain;
    }
}
// /Components

// Themes
@mixin t-brochure-button-solid-brand
{
    border-color: $brandPrimaryColor;
    background-color: $brandPrimaryColor;
    color: #fff;
}
@mixin t-brochure-button-solid-darker
{
    border-color: $brandPrimaryColorDarker;
    background-color: $brandPrimaryColorDarker;
    color: #fff;
}
@mixin t-brochure-button-solid-brand-gradient
{
    // background-color: ;
    color: #fff;
}

@mixin t-brochure-section-grey
{
    background-color: #eaeaea;
    color: $textColor;
}
@mixin t-brochure-section-white
{
    background-color: #fff;
    color: $textColor;
}

// /Themes




// Additions to general style
.video-container
{
    position: relative;
    width: 100%;
    height: 0;
}
.video-container
{
    padding-bottom: 44.73%;
}
.video-container[data-aspect-radio="16:9"]
{
    padding-bottom: 56.25%;
}
.video-container[data-aspect-radio="4:3"]
{
    padding-bottom: 75%;
}
.video-container img //Change to whatever element is used for video
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
}
.video-container-caption
{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 10;

    transform: translateY(-50%);
}
// /Additions to general style

// Brochure page general styles
.page-brochure
{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.page-brochure body
{
    padding-right: 0 !important;
    overflow: auto !important;
}
.page-brochure body::before
{
    height: 700px;
    background-image: url("../images/brochure-banner.jpg");
    background-position: 50% top;
    background-size: cover;
}
.page-brochure .masthead-container
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1040;
}
.footer.brochureware-footer
{
    margin-top: 0;
}
.page-brochure h2
{
    @include c-brochure-heading-2;

    color: $textColor;
}
.page-brochure hr
{
    margin: 40px 0 55px;
}
.page-brochure ul
{
    margin: 0 0 16px 0;
}
.page-brochure p
{
    margin: 16px 0;
}
.page-brochure .container-fluid
{
    width: 1046px;
    max-width: 100%;
    padding: 0 20px;
}

// Wtf, why all the specificity.
.brochureware-masthead .masthead .block-1 .nav-pills
{
    margin-bottom: 0;
}
.masthead .block-1 .nav-pills > li > a .icon-entry
{
    background: url("../../public/images/entry.png") no-repeat 0 0;
}
.masthead .block-1 .nav-pills > li > a.call-to-action
{
    @include c-brochure-button-solid;
    @include t-brochure-button-solid-brand;

    &:hover
    {
        @include t-brochure-button-solid-darker;
    }
}
.masthead .navbar-nav
{
    margin-top: 0;
    margin-bottom: 0;
}
@media screen and (max-width: 767px)
{
    .page-brochure hr
    {
        margin-left: -20px;
        margin-right: -20px;
        margin-top: 10px;
        margin-bottom: 24px;
    }
}
// /Brochure page general styles


// Brochure banner section
.brochure-banner
{
    clear: both;
    padding: 240px 0 125px;
}
.brochure-banner h1
{
    margin-bottom: 16px;

    font-size: 55px;
    font-weight: 300;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.4);
    text-align: center;
    color: #fff;
}
.brochure-banner sup
{
    top: -0.2em;
}
.brochure-banner p
{
    margin-bottom: 1em;
    font-size: 16px;
    text-align: center;
    color: #fff;
}
.brochure-banner a
{
    color: #fff;

    &:hover
    {
        text-decoration: none;
    }
}
.brochure-banner .brochure-banner-button
{
    @include c-brochure-button-solid-large-rounded;
    @include t-brochure-button-solid-brand;

    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.4);

    &:hover
    {
        @include t-brochure-button-solid-darker;
    }
}
@media screen and (max-width: 1330px)
{
    .page-brochure body::before
    {
        height: 550px;
        background-size: cover;
    }
    .brochure-banner
    {
        padding-top: 190px;
        padding-bottom: 70px;
    }
}
@media screen and (max-width: 767px)
{
    .page-brochure body
    {
        padding-top: 110px;
    }
    .page-brochure body::before
    {
        top: 110px;
        height: 280px;
    }
    .brochureware-masthead .masthead .navbar-user-account a
    {
        font-size: 12px;
    }
    // :(
    .brochureware-masthead .masthead .navbar-nav > li > a
    {
        height: auto;
        padding-left: 10px;
        padding-right: 5px;

        line-height: 1.1;
        font-weight: 500;
        text-align: left;
    }
    .brochureware-masthead .masthead .navbar-nav > li:first-child > a
    {
        padding-left: 0;
    }
    .brochureware-masthead .masthead .navbar-nav > li:last-child > a
    {
        padding-right: 0;
    }
    .masthead .navbar-nav > li > a:before
    {
        left: 0;
        height: 24px;
    }

    .brochure-banner
    {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .brochure-banner h1,
    .brochure-banner p
    {
        padding-left: 10px;
        padding-right: 10px;
        margin: 9px 0;
    }
    .brochure-banner h1
    {
        font-size: 34px;
    }
    .brochure-banner p:last-child
    {
        font-size: 14px;
        margin-bottom: 9px;
    }

}
// /Brochure banner section



// Brochure intro section
.brochure-section-intro
{
    @include c-brochure-section;
    @include t-brochure-section-grey;
}
.brochure-section-intro h2
{
    text-align: left;
    background-position: left bottom;
}
.brochure-section-intro li
{
    @include c-brochure-list-item-with-padding;
}
.brochure-section-intro li::before
{
    @include c-brochure-list-item-icon;

    background-image: url("../images/svg/tick-green.svg");
    background-size: 20px 20px;
}
.brochure-intro-cards
{
    position: relative;
    z-index: 1;
    display: block;
    margin: 0 auto -78px;
}
@media screen and (max-width: 767px)
{
    .brochure-section-intro li::before
    {
        background-size: contain;
    }
    .brochure-intro-cards
    {
        max-width: 100%;
        margin-bottom: -40px;
    }
}
// /Brochure intro section



// Brochure features section
.brochure-section-features
{
    @include c-brochure-section;
    @include t-brochure-section-white;
}
.brochure-section-features li
{
    @include c-brochure-list-item-with-padding;

    padding-right: 60px;
}
.brochure-section-features li::before
{
    @include c-brochure-list-item-icon;
}
.brochure-section-features .svg-tick::before
{
    background-image: url("../images/svg/tick-green.svg");
}
.brochure-section-features .svg-map::before
{
    background-image: url("../images/svg/map-green.svg");
    background-size: 25px 25px;
}
.brochure-section-features .svg-card::before
{
    background-image: url("../images/svg/card-green.svg");
    background-size: 29px 21px;
}
.brochure-section-features .svg-coin::before
{
    background-image: url("../images/svg/coin-green.svg");
    background-size: 27px 25px;
}
.brochure-use-location h3
{
    @include c-brochure-heading-3;
}
.brochure-use-location img
{
    display: block;
    margin: 0 auto 36px;
    border-radius: 50%;
}
.brochure-use-location p
{
    font-size: 14px;
}
@media screen and (max-width: 767px)
{
    .brochure-section-features h2
    {
        margin-top: 20px;
    }
    .brochure-use-location h3
    {
        font-size: 18px;
    }
    .brochure-section-features li
    {
        padding-right: 0;
    }
    .brochure-section-features .svg-map::before,
    .brochure-section-features .svg-card::before,
    .brochure-section-features .svg-coin::before
    {
        background-size: contain;
    }
    .brochure-use-location
    {
        padding-top: 25px;
    }
}
@media screen and (max-width: 479px)
{
    .brochure-use-location img
    {
        width: 90px;
        height: 90px;
        margin-bottom: 20px;
    }

}
// /Brochure features section





// Brochure how it works section
.brochure-section-how-it-works
{
    position: relative;
}
// /Brochure how it works section

// Brochure activate section
.brochure-section-activate
{
    @include c-brochure-section;
    @include t-brochure-section-grey;
}
.brochure-section-activate h2
{
    padding-bottom: 0;
    margin-bottom: 0;

    text-align: left;
    background-image: none;
}
.brochure-section-activate p
{
    margin: 0;

    font-size: 16px;
    font-weight: 300;
}
.brochure-section-activate a
{
    color: $brandPrimaryColor;

    &:hover,
    &:focus,
    &:active
    {
        color: #000;
    }
}
.brochure-section-activate .get-started-button
{
    @include c-brochure-button-solid-large-rounded;
    @include t-brochure-button-solid-brand;

    float: right;
    margin-top: 20px;

    &:hover
    {
        @include t-brochure-button-solid-darker;
    }
}
@media screen and (max-width: 767px)
{
    .brochure-section-activate .get-started-button
    {
        float: none;
    }
}
// /Brochure activate section



// Brochure exchange rates / fees and limits section
.brochure-section-exchange
{
    @include c-brochure-section;
    @include t-brochure-section-white;
}
.brochure-section-exchange a
{
    color: $brandPrimaryColor;

    &:hover,
    &:focus,
    &:active
    {
        color: #000;
    }
}
.exchange-rate-currencies
{
    position: relative;
    display: table;
    width: 100%;
    margin-bottom: 35px;
}
.exchange-rate-currencies::after
{
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;

    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    content: "";
}
.exchange-rate-currencies ul
{
    display: table-row;
}
.exchange-rate-currencies li
{
    display: table-cell;
}
.exchange-rate-currencies a
{
    position: relative;
    display: block;
    padding: 13px;

    font-size: 21px;
    font-weight: 700;
    text-decoration: none;
    color: #13100d;

    &:hover,
    &:focus,
    &:active
    {
        background-color: rgba(0, 0, 0, 0.1);
        color: #13100d;
    }
}
.exchange-rate-currencies li:first-child a
{
    margin-left: 7px;
}
.exchange-rate-currencies li:last-child a
{
    margin-right: 7px;
}
.exchange-rate-currencies a.active
{
    left: -1px;
    background-color: $brandPrimaryColorLighter;
    color: #fff;
}
.exchange-rate-currencies a::before
{
    position: absolute;
    right: 0;
    top: 13px;
    bottom: 13px;

    content: "";
    border-right: solid 1px #ccc;
}
.exchange-rate-currencies li:last-child a::before
{
    display: none;
}
.exchange-rate-currencies a.active::before
{
    display: none;
}
.exchange-rate-currencies a::after
{
    position: absolute;
    bottom: -19px;
    left: 0;
    right: 0;

    border-bottom: solid 2px transparent;
    content: "";
}
.exchange-rate-currencies a[aria-selected=true]::after
{
    bottom: -18px;
    border-bottom-width: 2px;
    border-color: $brandPrimaryColorLighter;
}
.exchange-rate-currencies a span
{
    display: block;
    font-size: 14px;
    font-weight: 400;
}

.exchange-rate-values
{
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 0.5s;
}
.exchange-rate-values[aria-hidden=false]
{
    max-height: 2000px;
    opacity: 1;
}
.exchange-rate-values h3
{
    font-size: 18px;
    font-weight: 700;
}
.exchange-rate-values p
{
    position: relative;
    z-index: 10;

    margin: 11px 0 -20px;

    font-size: 14px;
}
.exchange-rate-values label
{
    float: left;
    width: 30%;
    padding-top: 26px;

    font-size: 34px;
    font-weight: 700;
    color: #06161f;
}
.exchange-rate-values input
{
    width: 100%;
    padding: 12px 45px 12px 20px;
    border: 0;
    border-bottom: solid 1px #000;

    line-height: 1.2;
    font-size: 34px;
    font-weight: 700;
    text-align: right;
    color: #000;
}
.exchange-rate-values input:focus
{
    color: $brandPrimaryColorLighter;
}
.currency-input
{
    position: relative;
    float: left;
    width: 70%;
}
.currency-sign
{
    position: absolute;
    bottom: 15px;
    left: 0;
    z-index: 1;

    font-size: 21px;
    font-weight: 700;
}
.exchange-rate-values input:focus + .currency-sign
{
    color: $brandPrimaryColorLighter;
}
.currency-type
{
    position: absolute;
    bottom: 17px;
    right: 0;
    z-index: 1;

    font-size: 14px;
}
.exchange-rate-values input:focus ~ .currency-type
{
    color: $brandPrimaryColorLighter;
}
.currency-i-have
{
    float: left;
    width: 46%;
    padding-left: 20px;
}
.currency-equals
{
    float: left;
    width: 8%;
    padding-top: 36px;

    font-size: 34px;
    font-weight: 700;
    text-align: center;
    color: #06161f;
}
.currency-i-need
{
    float: right;
    width: 46%;
    padding-right: 20px;
}
.currency-rate
{
    clear: both;
    float: right;

    font-size: 12px;
    color: rgba(6, 22, 31, 0.7);
}
@media screen and (max-width: 767px)
{
    .exchange-rate-currencies,
    .exchange-rate-currencies ul,
    .exchange-rate-currencies li
    {
        display: block;
    }
    .exchange-rate-currencies::after
    {
        display: none;
    }
    .exchange-rate-currencies a
    {
        padding-left: 0;

        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }
    .exchange-rate-currencies li:first-child a
    {
        margin-left: 0;
    }
    .exchange-rate-currencies li:last-child a
    {
        margin-right: 0;
    }
    .exchange-rate-currencies a.active
    {
        left: auto;
        border-bottom: 0;
        background-color: transparent;
        color: #06161f;
    }
    .exchange-rate-currencies a::before
    {
        display: none;
    }
    .exchange-rate-currencies a.active + .exchange-rate-values
    {
        padding-bottom: 18px;
        border-bottom: solid 3px $brandPrimaryColor;
    }
    .exchange-rate-currencies a::after
    {
        display: none;
    }
    .exchange-rate-currencies a span
    {
        display: inline-block;
        line-height: 2.14;
        vertical-align: top;
    }

    .exchange-rate-values h3
    {
        margin-bottom: 0;

        font-size: 14px;
        font-weight: 400;
    }
    .exchange-rate-values p
    {
        display: none;
    }
    .exchange-rate-values label
    {
        float: none;
        width: auto;
        padding-top: 5px;
        margin-bottom: 0;

        font-size: 21px;
    }
    .currency-input
    {
        float: none;
        width: auto;
    }
    .exchange-rate-values input
    {
        padding-right: 35px;
        padding-top: 9px;
        padding-bottom: 9px;

        font-size: 18px;
    }
    .currency-sign
    {
        bottom: 11px;

        font-size: 14px;
    }
    .currency-type
    {
        bottom: 11px;

        font-size: 14px;
    }
    .currency-i-have
    {
        width: 42%;
        padding-left: 0;
    }
    .currency-i-need
    {
        width: 42%;
        padding-right: 0;
    }
    .currency-equals
    {
        width: 16%;

        padding-top: 58px;
        font-size: 14px;
    }
    .currency-rate
    {
        display: none;
    }
}




.fees-and-limits-container p
{
    margin-bottom: 35px;
}
.fees-and-limits-menu
{
    overflow: hidden;
    padding: 0;
    margin-bottom: 40px;
}
.fees-and-limits-menu a
{
    float: left;
    width: 50%;
    padding: 10px 20px;

    font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    background-color: #000;
    color: #fff;

    &:hover,
    &:focus,
    &:active
    {
        background-color: $brandPrimaryColorLighter;
        color: #fff;
    }
}
.fees-and-limits-menu a.active
{
    background-color: $brandPrimaryColorLighter;
}
.fees-and-limits-table-container i
{
    margin-right: 12px;
}
.custom-icon
{
    display: inline-block;
    background: no-repeat 0 0;
    vertical-align: middle;
}
.custom-icon.cash
{
    width: 28px;
    height: 15px;
    background-image: url("../images/icon-cash-white.png");
}
.custom-icon.card
{
    width: 18px;
    height: 14px;
    background-image: url("../images/icon-card-white.png");
}

.fees-and-limits-wrapper
{
    overflow: hidden;
    max-height: 0;
    margin: 0;
    opacity: 0;
    transition: all 0.5s;
}
.fees-and-limits-wrapper[aria-hidden=false]
{
    opacity: 1;
    max-height: 2000px;
    margin-bottom: 1.5em;
}
.fees-and-limits-table-container
{
    overflow: hidden;
    max-height: 0;
    margin: 0;
    opacity: 0;
    transition: opacity 0.5s;
}
.fees-and-limits-table-container[aria-hidden=false]
{
    opacity: 1;
    max-height: 2000px;
    margin-bottom: 1.5em;
}
.fees-and-limits-table-container table
{
    width: 100%;
    border: 0;
    border-collapse: separate;
}
.fees-and-limits-table-container caption
{
    position: absolute;
    left: -10000em;
}
.fees-and-limits-table-container th
{
    font-size: 18px;
}
.fees-and-limits-table-container th,
.fees-and-limits-table-container td
{
    width: 50%;
    padding: 20px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    margin: 0;
    vertical-align: top;
}
.fees-and-limits-table-container th:first-child,
.fees-and-limits-table-container td:first-child
{
    border-right: solid 1px rgba(0, 0, 0, 0.1);
}
.fees-and-limits-table-container tr th
{
    padding-top: 0;
}
@media screen and (max-width: 767px)
{
    .fees-and-limits-table-container caption
    {
        margin-bottom: 20px;
    }
    .fees-and-limits-table-container th,
    .fees-and-limits-table-container td
    {
        padding: 15px;
    }
    .fees-and-limits-table-container th:first-child,
    .fees-and-limits-table-container td:first-child
    {
        padding-left: 0;
    }
    .fees-and-limits-table-container th:last-child,
    .fees-and-limits-table-container td:last-child
    {
        padding-right: 0;
    }
}

// /Brochure exchange rates / fees and limits section


// Brochure activate 2 section
.brochure-section-activate-2
{
    @include c-brochure-section;
    @include t-brochure-section-grey;

    background-image: url("../images/activate-banner.jpg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 50% 100%;
}
.brochure-section-activate-2 h2
{
    padding-bottom: 0;
    margin-bottom: 0;

    background-image: none;
    text-align: left;
}
.brochure-section-activate-2 img
{
    display: block;
    margin-left: -40px;
    margin-bottom: -60px;
}
.brochure-section-activate-2 a
{
    color: $brandPrimaryColor;
}
.brochure-section-activate-2 .activate-button
{
    @include c-brochure-button-solid-large-rounded;
    @include t-brochure-button-solid-brand;

    &:hover
    {
        @include t-brochure-button-solid-darker;
    }
}
@media screen and (max-width: 991px)
{
    .brochure-section-activate-2 img
    {
        max-width: 100%;
        margin-left: 0;
    }
}
@media screen and (max-width: 767px)
{
    .brochure-section-activate-2
    {
        background-size: 100% 220px;
    }
    .brochure-section-activate-2 img
    {
        position: relative;
        bottom: -100px;
        max-width: 100%;
        margin-bottom: 0;
    }
    .brochure-section-activate-2 h2
    {
        margin-top: 70px;
    }
}
// Brochure activate 2 section



// Brochure faqs section
.brochure-section-faqs
{
    @include c-brochure-section;
    @include t-brochure-section-white;
}
.brochure-section-faqs h2
{
    padding: 0 27%;
    margin: 0;

    cursor: pointer;
    background: url("../images/svg/plus.svg") no-repeat 98% 50%;
}
.brochure-section-faqs h2.active
{
    background: url("../images/svg/minus.svg") no-repeat 98% 50%;
}
.brochure-section-faqs dl
{
    overflow: hidden;
    opacity: 0;
    width: 835px;
    max-width: 100%;
    max-height: 0;
    margin: 0 auto;
    transition: all 0.5s;
}
.brochure-section-faqs dl[aria-hidden=false]
{
    opacity: 1;
    max-height: 4000px;
    margin: 16px auto;
}
.brochure-section-faqs dt
{
    padding: 15px 20px;
    border-top: solid 1px rgba(0, 0, 0, 0.1);

    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    background: url("../images/svg/plus.svg") no-repeat 98% 18px;
}
.brochure-section-faqs dt.active
{
    background-image: url("../images/svg/minus.svg");
    background-position: 98% 27px;
}
.brochure-section-faqs dd
{
    overflow: hidden;
    opacity: 0;
    max-height: 1px;
    padding-left: 20px;
    padding-right: 15%;
    transition: all 0.5s;
}
.brochure-section-faqs dd[aria-hidden=false]
{
    opacity: 1;
    max-height: 1000px;
    padding-bottom: 15px;

    font-size: 14px;
}
@media screen and (max-width: 767px)
{
    .brochure-section-faqs h2
    {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.contact-support-container
{
    overflow: hidden;
    width: 835px;
    max-width: 100%;
    max-height: 0;
    margin: 0 auto;
    opacity: 0;
    transition: all 0.5s;
}
.contact-support-container[aria-hidden=false]
{
    max-height: 2000px;
    opacity: 1;
}
.contact-support-container h3
{
    @include c-brochure-heading-4;
}
.contact-support-container > p
{
    font-size: 18px;
    text-align: center;
}
.support-numbers
{
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;

    font-size: 14px;
}
.support-numbers p
{
    padding: 15px 20px;
    margin: 0;
}
.support-numbers strong
{
    display: block;

    font-size: 20px;
    font-weight: 700;
}
@media screen and (max-width: 767px)
{
    .contact-support-container > p:first-of-type
    {
        font-size: 14px;
    }
    .support-numbers strong
    {
        font-size: 14px;
    }
}

// Brochure faqs section

// Activate new / replacement card modal

.brochure-modal .modal-header
{
    margin-bottom: 30px;
}
.brochure-modal .row
{
    margin-left: -20px;
    margin-right: -20px;
}
.brochure-modal a[aria-role="button"]
{
    @include c-brochure-button-solid-large-rounded;
    @include t-brochure-button-solid-brand;

    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);

    &:hover,
    &:focus,
    &:active
    {
        @include t-brochure-button-solid-darker;
    }
}
.brochure-modal h2
{
    padding-bottom: 0;
    padding-right: 50px;
    margin-bottom: 0;

    text-align: left;
    background-image: none;
}
.brochure-modal p
{
    color: #000;
}
.brochure-modal .modal-content button
{
    top: -35px;
    right: -30px;
    z-index: 10;

    padding: 15px 20px;
    margin-top: 0;
}
.brochure-modal .modal-content button span
{
    display: block;
    width: 14px;
    height: 14px;

    background: url("../../public/images/close.png") no-repeat 0 0;
}

.brochure-modal .new-card
{
    padding-right: 25px;
    background: url("../../public/images/modal-divider.png") repeat-y right top;
}
.brochure-modal .replacement-card
{
    padding-left: 25px;
}
.brochure-modal p:last-child
{
    margin-bottom: 0;
}

@media screen and (max-width: 767px)
{
    .brochure-modal .new-card
    {
        padding-right: 5px;
        padding-bottom: 25px;
        background-image: url("../../public/images/modal-divider-horizontal.png");
        background-position: center bottom;
        background-repeat: repeat-x;
    }
    .brochure-modal .replacement-card
    {
        padding-left: 5px;
        padding-top: 15px;
    }
    .brochure-modal .modal-content button
    {
        top: -12px;
    }
}

// /Activate new / replacement card modal
