﻿@font-face{
font-family:"Helvetica Neue";
src:url("../fonts/helvetica-neue/heavy-700/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix");
src:url("../fonts/helvetica-neue/heavy-700/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"),
    url("../fonts/helvetica-neue/heavy-700/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"),
    url("../fonts/helvetica-neue/heavy-700/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff"),
    url("../fonts/helvetica-neue/heavy-700/419a308d-b777-4f84-9235-2caf4a98ec23.ttf") format("truetype"),
    url("../fonts/helvetica-neue/heavy-700/e4d5b881-6835-45b7-8766-3e5b9e7cab8b.svg#e4d5b881-6835-45b7-8766-3e5b9e7cab8b") format("svg");
font-weight: 700;
font-style: normal;
}
@font-face{
font-family:"Helvetica Neue";
src:url("../fonts/helvetica-neue/regular-500/9c4e9a25-0ac9-484d-bdb3-6b1796284fd2.eot?#iefix");
src:url("../fonts/helvetica-neue/regular-500/9c4e9a25-0ac9-484d-bdb3-6b1796284fd2.eot?#iefix") format("eot"),
    url("../fonts/helvetica-neue/regular-500/24df3ebe-5cf3-4837-b2b4-3d8a8a828ceb.woff2") format("woff2"),
    url("../fonts/helvetica-neue/regular-500/24aa46ca-2f27-4ad9-9ccf-a11760067406.woff") format("woff"),
    url("../fonts/helvetica-neue/regular-500/d44e416e-85fc-4570-b097-900d5fa1b9ec.ttf") format("truetype"),
    url("../fonts/helvetica-neue/regular-500/c3751338-a183-4cfc-a78e-38634ce6206a.svg#c3751338-a183-4cfc-a78e-38634ce6206a") format("svg");
font-weight: 500;
font-style: normal;
}
@font-face{
font-family:"Helvetica Neue";
src:url("../fonts/helvetica-neue/light-300/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix");
src:url("../fonts/helvetica-neue/light-300/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot"),
    url("../fonts/helvetica-neue/light-300/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"),
    url("../fonts/helvetica-neue/light-300/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff"),
    url("../fonts/helvetica-neue/light-300/0be5590f-8353-4ef3-ada1-43ac380859f8.ttf") format("truetype"),
    url("../fonts/helvetica-neue/light-300/82a4e1c2-7ffd-4c58-86fa-4010a6723c8e.svg#82a4e1c2-7ffd-4c58-86fa-4010a6723c8e") format("svg");
font-weight: 300;
font-style: normal;
}




