/*table-transactions*/
.table-transactions{ margin-bottom:10px;}
.table-transactions .main-transaction { font-weight:700; }
.table-transactions__detail{ width:100%; }
.table-transactions__detail td:last-child{ text-align:right; padding-right:20px; }

.table-transactions__detail .curr{ margin-left:5px; }
.table-transactions .col-transaction-type { vertical-align:middle; width:60px; text-align:center; }
.table-transactions .col-transaction-type .glyphicon,
.table-transactions .col-transaction-type .icon { font-size:13px; margin-top:6px;}

.table.table-transactions > tbody > tr > td{ padding-top:15px; padding-bottom:15px;}

a.toggle-transaction-details {
    text-decoration: underline !important;
}

.table-transactions {
  .col-transaction-type {
    vertical-align: top;

    i {
      margin-top: 20px;
    }
  }
}


.table-transactions__detail {
    .date{
        padding-bottom:2px;
    }

  .show-details-text {
    display: block;
  }
  .hide-details-text {
    display: none;
  }
  .detail {
    display: none;
  }
}
.table-transactions__detail.details-showing {
  .main-transaction td {
    padding-bottom: 8px;
  }

  .show-details-text {
    display: none;
  }
  .hide-details-text {
    display: block;
  }
  .detail {
    display: table-row;
  }
}



@media (max-width: $screen-xs-max) {
   .table-transactions .col-transaction-type { width:40px; display:none; }
   .table-transactions__detail td:last-child{ padding-right:7px; }
   .table-transactions__detail td:first-child{ padding-left:8px; }
}
