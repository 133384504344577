/*------------------------------------------------------------------common */
* { position: relative;}
html { -webkit-overflow-scrolling: touch; background-color:#000;}
body { color:#13100d;  -webkit-overflow-scrolling: touch; background-color:#13100d; background-color: #000; }
body:before { content:""; display:block; position:absolute; width:100%; height:740px; background:url(../images/bg.jpg) no-repeat; background-size:cover;  }


h1, h2, h3, h4, h5, h6 { margin: 0 0 0.5em 0; }
h2{font-weight:700; font-size:34px; line-height:1.3; }
h3{font-weight:700; font-size:21px; }
h5{font-weight:700;}
p{ margin:0; padding:0;}
ul{padding-left:0;}

a { text-decoration: underline; }
a:hover { cursor: pointer; }
.subbed {
  padding-top: 10px;
  font-weight: bold;
  font-size: 16px;
}

.flag{ width:90px; height:90px; border:1px solid rgba(0, 0, 0, 0.1);  }
.icon{ width:1em; height:1em; display:inline-block;}
.icon.icon-padded-right {
  padding-right: 26px;
}


/*general elements*/
.tabProgression{ width:100%; padding-top:20px; float:left; }
.tabProgression.border-top-line:before{ padding-top:20px; width:calc(100% - 10px); margin-left:5px; }
.plus-link{ font-weight:700; color:#13100d; text-decoration:none; margin-top:12px; margin-bottom:20px; float:left;}
.plus-link .icon{ font-size:.7em; top:-1px;}
.content-container {margin-top: 20px; float:left; }
.description-text .icon{ top:1px; margin-right:5px;}

.system-maintenance-banners{ background:#000; color:#fff; border-bottom:4px solid #0099a3; position:relative; width:100%; left:0; bottom:0; z-index:2000; padding:40px 0;}
.system-maintenance-banners h3{ color:#0099a3;}
.system-maintenance-banners a{ color:#0099a3;}
.system-maintenance-banners p a{ color:#fff;}
.system-maintenance-banners .btn-remove{ position:absolute; top:-20px; right:5px; text-decoration:none; color:#fff; }

@media (max-width: $screen-xs-max) {
    h2{ font-size:21px; margin-bottom: 20px;}
    h3{ font-size:18px;}
}
