/*form-definition-list*/
.form-definition-list { margin-bottom:20px; padding:10px 20px;}
.form-definition-list li{ padding:10px 0; border-bottom:1px solid #eee; list-style:none; display:block; float:left; width:100%; margin-left:0 !important;}
.form-definition-list li.has-description{ margin-bottom:30px; }
.form-definition-list .dt,
.form-definition-list .dd { display:inline-block; float:left; }
.form-definition-list .dt { width:40%; padding-right:15px; color:#777; margin-bottom:10px; padding-left:7px; }
.form-definition-list.borderd .dt { padding-left:0; width:calc(40% - 7px); }

.form-definition-list .dd { width:60%; }
.form-definition-list .dd.dd-50 { width:29%; }
.form-definition-list .dd.dd-50.second { width:29%; margin-left:2%;  }
.form-definition-list .dd .description-text{ position:absolute;top:32px; font-size:12px; font-weight:normal;}


.form-definition-list .description-text{ font-size:12px;}
.form-definition-list .description-text--indent{ margin-left:40%; }




.form-definition-list.borderd{ border:2px solid $brand-primary; }
.form-definition-list.copyable.borderd .col-copy{ right:-65px; top:14px; color:$brand-primary; }
.form-definition-list.copyable li{ border:none; }
.form-definition-list.copyable .dd { border-bottom:1px solid #000; font-weight:700; padding-bottom:5px; }
.form-definition-list.copyable .dd.blank { border-bottom:none; padding-bottom:0;  }
.form-definition-list.copyable .dd.blank .description-text{ top:2px; }
.form-definition-list.copyable .col-copy{ position:absolute; right:-66px; font-weight:700; color:$brand-primary;  }
.form-definition-list.borderd.copyable .col-copy{ right:-63px;  }
.form-definition-list.copyable .col-copy a { color:$brand-primary;  }





@media (max-width: $screen-xs-max) {

    .form-definition-list{ padding:0 5px; }
    .form-definition-list li{ padding:10px 0;}
    .form-definition-list .dt,
    .form-definition-list .dd{ width:100%;}
    .form-definition-list .dt{ padding-left:0;}
    .form-definition-list .dd.dd-50{ width:48%;}
    .form-definition-list .dd.dd-50.second{ width:48%; margin-left:4%}

    .form-definition-list.copyable{ width:calc(100% - 50px); float:left; }
    .form-definition-list.copyable .col-copy{ right:-50px; padding-top:30px;}
    .form-definition-list.copyable.borderd .col-copy{ right:-50px;}
    .form-definition-list.copyable .dt.blank{ display:none; }
    .form-definition-list .description-text--indent{ margin-left:0; }
    .form-definition-list.borderd.copyable li{ padding-left:10px; padding-right:10px; }
    .form-definition-list.borderd .dt { width:100%; }


}
