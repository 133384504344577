

/*Modal*/
.modal-backdrop {
  /* z-index: 1040 */
}

.modal-dialog {
  max-width: 600px;
  z-index: 1050;
  border-top:3px solid #000;
  /*
  This is breaking the modals on mobile - no margins from sides!
  margin-left:auto;
  margin-right:auto;
  */
}

.modal-header {
  border: 0;
  padding:0;
  margin-bottom:20px;

  .modal-title {
    line-height:1.2;
    font-size:34px;
    font-weight:700;

    @media (max-width: $screen-xs-max) {
      font-size: 30px;
    }
  }
}

.modal-content {
  padding:40px;
  border-radius: 0;

  @media (max-width: $screen-xs-max) {
    padding: 12px;
  }
}

.modal-content .close { position:absolute; top:15px; right:30px; opacity:1;  }
.modal-content .close .icon{ font-size:14px; color:$brand-primary;  }

.modal-body {
  /* padding: 15px 20px 0 20px;*/
  padding: 0;
}

.modal-footer {
  border: 0;
  padding:0;
}



/*Modals*/
.modal--login .modal-dialog { max-width:500px; margin-left:auto; margin-right:auto;   }
.modal--login .modal-content{  padding-left:112px; padding-right:112px; }
.modal-footer .hr-double-dot{ margin-bottom:20px; margin-top:20px; }



 @media (max-width: $screen-xs-max) {
   .modal-content {
     padding: 20px !important;
   }
     .modal-header .modal-title{ font-size:21px;}
}


  @media (max-width: 540px) {
     .modal--login .modal-dialog { margin-left:15px; margin-right:15px; }
}
