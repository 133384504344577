.form-padding {
  padding-top: 15px;
}

/*.copy-buttons {

}

.copy-buttons label {
  line-height: 26px;
}*/

.form-horizontal.left-aligned-labels .control-label {
  text-align: left;
}

.dropdown-menu li {
  border-bottom: 1px solid black;

  .checkbox {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;

    margin: 0;

    input {
      display: none;
    }

    label {
      width: 100%;
      font-size: 14px;
    }
  }
}

.dropdown-menu li:last-child {
  border-bottom: 0;
}


.pin-box {
    padding: 10px;
    border: 1px solid #ddd;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 26px;
}

.cvn-box {
    padding: 10px;
    border: 0;
    border-bottom: 1px solid #13100d;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 26px;
    margin-right: 14px;
}

.cvn-box:focus {
  border-bottom: 1px solid $brand-primary;
  color: $brand-primary;
  outline-width: 0;
  outline: none;
}

.form-control, .form-group .form-control {
  background-image: linear-gradient($brand-primary, $brand-primary), linear-gradient(#13100d, #13100d);
  //background-image: linear-gradient(#D2D2D2, #D2D2D2), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 0 2px, 100% 2px;
  color:#13100d;
  //background: none !important;
  //background-image: linear-gradient(#13100d, #13100d);
  //background-size: 100% 1px;
  //border-bottom: 1px solid #13100d;
  //background-position: center bottom, center calc(100% - 0px);
}
.is-focused .form-control {
  //background-image: linear-gradient(#00677e, #00677e), linear-gradient(#D2D2D2, #D2D2D2)  !important;;
  //background-size: 0 2px, 100% 1px !important;
  /*margin-top: 2px;*/
  //border-bottom: 1px solid transparent;
  //padding-bottom: 18px !important;

  //border-bottom: 2px solid $brand-primary;
}


.form-group label.control-label {
  color: #767676;
}

.form-group.is-empty.is-focused label.control-label {
  font-size: 14px;
  color:#666;
}
.form-group.label-floating:not(.is-empty) label.control-label {
    font-size: 14px !important;
}



.form-group.skeleton {
  padding: 0;
  margin: 0;
}

#cvn {
  width: 100%;
  border: 0;
  font-size: 40px;
  text-align: left;
  background-image: none;
}

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}

.checkbox .checkbox-material .check,
label.checkbox-inline .checkbox-material .check {
    width: 20px;
    height: 20px;
    border-radius: 0;
}
/*
input[type=text].form-control {
  margin-bottom: -2px;
  border-bottom: 1px solid black;
  background: none !important;
}

input[type=text].form-control:focus {
  border-bottom: 1px solid #00677e;
}
*/

/*remove type=date placeholder*/
input[type=date]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
}
input[type=date]::-webkit-datetime-edit-month-field{
    -webkit-appearance: none;
}
input[type=date]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
}
input[type=date]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
}


/*popover--custom*/
.form-group .popover--custom{ position:absolute; right:0; top:13px; z-index:10; cursor:pointer; color:#000; }
.form-group .optional-label{ position:absolute; font-size:12px; color:#999; right:0; bottom:8px;}
