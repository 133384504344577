
/*.currency-detail-row {
  padding: 8px 0;

  div[class*='col-'] {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }

  h2 {
    padding: 0;
    margin: 0;
  }

  h3 {
    padding: 3px 0;
    margin: 0;
  }

  p {
    padding: 0;
    margin: 0;
  }
}*/


