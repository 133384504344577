﻿
.toggle-transaction-filters{ padding-left:20px; padding-right:20px;  }
.toggle-transaction-filters--link{ padding:0; text-decoration:none !important;}
.transactional-history-filter .form-group{ margin:0; }
.transactional-history-filter .btn-go { margin-top:15px; }

.show-filter .toggle-transaction-filters{ display:none; }
.page-transactions .btn-print{ display:inline-block; float:right; margin-top:-45px;   }
.page-transactions .btn-print .icon-print{ margin-right:5px;}

#transaction-filters .form-group {
  label,
  input,
  select {
    font-size:14px !important;
  }

  ::-webkit-input-placeholder { font-size:14px !important; }
  ::-moz-placeholder { font-size:14px !important; } /* firefox 19+ */
  :-ms-input-placeholder { font-size:14px !important; } /* ie */
  input:-moz-placeholder { font-size:14px !important; }
}

#statements,
#transactional-history {
  h3 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  @media (max-width: $screen-xs-max) {
    margin-top: 0;
  }
}

#statements h3 {
  margin-bottom: 20px;
}

a.toggle-transaction-details {
  /*color: #13100d;*/

}

#transactions-nav-tabs > li > a {
  /* min-height: 65px; */
}

/*
#transactions-nav-tabs {
  li > a {
    text-align: left;
  }
}
*/

#transactions-nav-tabs + .tab-content {
  padding-top: 30px !important;

  @media (max-width: $screen-xs-max) {
    padding-top: 20px !important;
  }
}

.transaction-filters-head {
  margin-bottom: 15px;
  @media (max-width: $screen-xs-max) {
    .toggle-transaction-filters {
      padding-left: 10px;
      padding-right: 10px;
      width: 88px;
    }
  }
}
