/*payees*/
.payee-list{ list-style:none; }
.payee-list .payee-item{ padding:15px 0; border-top:1px solid #ccc; float:left; width:100%; }
.payee-list .payee-item:last-child{ border-bottom:1px solid #ccc; }
.payee-list .payee-item .col-icon { width:10%; padding-left:25px;  }
.payee-list .payee-item .col-name { width:20%;}
.payee-list .payee-item .col-number{ width:30%;}
.payee-list .payee-item .col-func { width:40%; text-align:right; }
.payee-list .payee-item .col-func .btn,
.payee-list .payee-item .col-func a { margin-left:20px; }
.payee-list .payee-item:hover{
     .hover-visible{ display:inline; }
}

.payee-list .payee-item.selectable:hover{ background-color:#f9f9f9; cursor:pointer;  }
.payee-list .payee-item.selected{ background-color:#f9f9f9; border:1px solid #e5e5e5; margin-top:-30px; }
.payee-list .payee-item.selected .col-func{ padding-right:20px; }
.payee-list .payee-item.selected .col-func a { display:inline-block !important; }
.payee-list.selectable .payee-item:hover{ cursor:pointer; background-color:#f9f9f9; }

.payee-from-to .col-arrow-right{ text-align:center; padding-top:30px;  }
.payee-from-to .col-arrow-right i:before { @include rotate(-90deg); }  
.payee-from-to .payee{ background-color:#f9f9f9; border:#e5e5e5 solid 1px; padding:20px; }

// Add some colouring


@media (max-width: $screen-xs-max) {
    .payee-from-to .payee{ padding:10px;}
    .payee-from-to .col-arrow-right{ width:34px;}
    .payee-from-to .payee{ width:calc(50% - 17px); }

    .payee-list .payee-item .col-icon{ width:50px; padding-left:20px; }
    .payee-list .payee-item .col-name{ width:calc(100% - 50px);  }
    .payee-list .payee-item .col-number{ width:100%; padding-left:50px; }
    .payee-list .payee-item .col-func{width:100%; padding-left:50px; text-align:left; padding-top:10px;  }
    .payee-list .payee-item .col-func .btn,
    .payee-list .payee-item .col-func a{ margin-left:0; margin-right:10px;}

    .payee-item .col-func {
      /*visibility: hidden;*/
      display: none;
      @include transition(all, 0.6s);
    }

    .payee-item.activated {
      background-color: #f9f9f9;
      .col-func {
        /*visibility: visible;*/
        display: block;
        @include transition(all, 0.6s);
      }
    }
}
