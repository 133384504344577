.last-updated {
  font-size: 12px;
  padding-left: 16px;
}

.show-change-password {
   font-weight: bold;
   color: #13100d;
}

.delete-payee {
  font-weight: bold;
}

.card-settings-display .border-bottom-line:last-child {
  margin-bottom: 20px;
}
.default-currency-block .inner { padding:20px 0; }
.default-currency-block .btn-change { font-size:14px; }

.card-settings-display {
  h4 {
    margin-bottom: 5px;
  }
  .detailed-breakdown {
    margin-bottom: 20px;
    padding: 0 20px;

    @media (max-width: $screen-xs-max) {
      padding: 0;
    }

    .detail-label {
      color: #777;
      width: 40%;
    }

    .detail-text {
      width: 60%;
      font-weight:700;
    }

    .row {
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      margin: 0;
    }
  }

  .card {
    padding-top: 17px;
  }
}

.small-heading-text {
  font-size: 14px;
}

.padded-left-link {
  padding-left: 10px;
}

.details-color {
  color: #005698;
}

#load-setting-main .icon-transfer { margin-right:6px; }
#load-setting-main .transfer-p { margin-left:33px; }  



/*------------------------------------------------------------------Mobile*/
@media (max-width: $screen-xs-max) {
    .card-settings-display {
        .card{ width:130px; }
    }
    .last-updated {
          display:block;
          width:100%;
          padding-left:0;
        }

}
