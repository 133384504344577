


/**** GREEN ID ****/
#greenid-intro-content {
  margin-bottom: 20px;
  // HEADINGS
  h1 {
      font-weight: 700;
      font-size: 34px;
      line-height: 1.3;
      margin: 0 0 0.5em 0;
  }

  .lead {
    margin: 0;
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }
}

#greenid-submit-button-container {
  text-align: right;
  padding-bottom: 30px;

  @media (max-width: $screen-sm-min) {
    text-align: center;
  }


}

#greenid-container {

  // FORMS
  // This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

  @mixin mdb-label-color-toggle-focus(){
    // override bootstrap focus and keep all the standard color (could be multiple radios in the form group)
    .form-group.is-focused & {
      color: $mdb-label-color;

      // on focus just darken the specific labels, do not turn them to the brand-primary
      &:hover,
      &:focus {
        color: $mdb-label-color-toggle-focus;
      }

      // correct the above focus color for disabled items
      fieldset[disabled] & {
        color: $mdb-label-color;
      }
    }
  }

  .form-horizontal {

    // Consistent vertical alignment of radios and checkboxes
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
      padding-top: 0;
    }

    .radio {
      margin-bottom: 10px;
    }

    label {
      text-align: right;
    }

    label.control-label {
      margin: 0;
    }
  }



  // INPUTS SIZE
  // This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

  //
  // Forms - sizing - material - mirrors bootstrap/forms.less with custom sizing
  //
  // LEAVE THIS IDENTICAL TO THE BOOTSTRAP FILE - DO NOT CUSTOMIZE HERE.
  //
  // NOTE: this is intentionally kept structurally _identical_ to the bootstrap/forms.less file to make it easier
  //        to identify differences in sizing approaches to form inputs.
  // --------------------------------------------------

  legend {
    margin-bottom: $mdb-input-line-height-computed;
    font-size: ($mdb-input-font-size-base * 1.5);
  }

  // Adjust output element
  output {
    padding-top: ($mdb-input-padding-base-vertical + 1);
    font-size: $mdb-input-font-size-base;
    line-height: $mdb-input-line-height-base;
  }

  .form-control {
    height: $mdb-input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: $mdb-input-padding-base-vertical $mdb-input-padding-base-horizontal;
    font-size: $mdb-input-font-size-base;
    line-height: $mdb-input-line-height-base;
  }

  // Special styles for iOS temporal inputs
  //
  // In Mobile Safari, setting `display: block` on temporal inputs causes the
  // text within the input to become vertically misaligned. As a workaround, we
  // set a pixel line-height that matches the given height of the input, but only
  // for Safari. See https://bugs.webkit.org/show_bug.cgi?id=139848
  //
  // Note that as of 8.3, iOS doesn't support `datetime` or `week`.

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
      &.form-control {
        line-height: $mdb-input-height-base;
      }

      &.input-sm,
      .input-group-sm & {
        line-height: $mdb-input-height-small;
      }

      &.input-lg,
      .input-group-lg & {
        line-height: $mdb-input-height-large;
      }
    }
  }

  .radio,
  .checkbox {

    label {
      min-height: $mdb-input-line-height-computed; // Ensure the input doesn't jump when there is no text
    }
  }


  // Static form control text
  //
  // Apply class to a `p` element to make any string of text align with labels in
  // a horizontal form layout.

  .form-control-static {
    // Size it appropriately next to real form controls
    padding-top: ($mdb-input-padding-base-vertical + 1);
    padding-bottom: ($mdb-input-padding-base-vertical + 1);
    min-height: ($mdb-input-line-height-computed + $mdb-input-font-size-base);
  }


  // Form control sizing
  //
  // Relative text size, padding, and border-radii changes for form controls. For
  // horizontal sizing, wrap controls in the predefined grid classes. `<select>`
  // element gets special love because it's special, and that's a fact!

  // mixin pulled from bootstrap and altered for less/sass compatibility with sass parent hack.
  //  bootstrap-sass has this one, but we would have to then convert it back to less.  chicken meet egg.
  @mixin input-size($parent, $mdb-input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius){

    #{$parent} {
      height: $mdb-input-height;
      padding: $padding-vertical $padding-horizontal;
      font-size: $font-size;
      line-height: $line-height;
      border-radius: $border-radius;
    }

    select#{$parent} {
      height: $mdb-input-height;
      line-height: $mdb-input-height;
    }

    textarea#{$parent},
    select[multiple]#{$parent} {
      height: auto;
    }
  }



  // Form control sizing
  //
  // Build on `.form-control` with modifier classes to decrease or increase the
  // height and font-size of form controls.
  //
  // The `.form-group-* form-control` variations are sadly duplicated to avoid the
  // issue documented in https://github.com/twbs/bootstrap/issues/15074.
  .input-sm {
    @include input-size(unquote(".input-sm"), $mdb-input-height-small, $mdb-input-padding-small-vertical, $mdb-input-padding-small-horizontal, $mdb-input-font-size-small, $mdb-input-line-height-small, $mdb-input-border-radius-small);
  }
  .form-group-sm {
    .form-control {
      height: $mdb-input-height-small;
      padding: $mdb-input-padding-small-vertical $mdb-input-padding-small-horizontal;
      font-size: $mdb-input-font-size-small;
      line-height: $mdb-input-line-height-small;
    }
    select.form-control {
      height: $mdb-input-height-small;
      line-height: $mdb-input-height-small;
    }
    textarea.form-control,
    select[multiple].form-control {
      height: auto;
    }
    .form-control-static {
      height: $mdb-input-height-small;
      min-height: ($mdb-input-line-height-computed + $mdb-input-font-size-small);
      padding: ($mdb-input-padding-small-vertical + 1) $mdb-input-padding-small-horizontal;
      font-size: $mdb-input-font-size-small;
      line-height: $mdb-input-line-height-small;
    }
  }

  .input-lg {
    @include input-size(unquote(".input-lg"), $mdb-input-height-large, $mdb-input-padding-large-vertical, $mdb-input-padding-large-horizontal, $mdb-input-font-size-large, $mdb-input-line-height-large, $mdb-input-border-radius-large);
  }
  .form-group-lg {
    .form-control {
      height: $mdb-input-height-large;
      padding: $mdb-input-padding-large-vertical $mdb-input-padding-large-horizontal;
      font-size: $mdb-input-font-size-large;
      line-height: $mdb-input-line-height-large;
    }
    select.form-control {
      height: $mdb-input-height-large;
      line-height: $mdb-input-height-large;
    }
    textarea.form-control,
    select[multiple].form-control {
      height: auto;
    }
    .form-control-static {
      height: $mdb-input-height-large;
      min-height: ($mdb-input-line-height-computed + $mdb-input-font-size-large);
      padding: ($mdb-input-padding-large-vertical + 1) $mdb-input-padding-large-horizontal;
      font-size: $mdb-input-font-size-large;
      line-height: $mdb-input-line-height-large;
    }
  }


  .form-horizontal {

    // Consistent vertical alignment of radios and checkboxes
    //
    // Labels also get some reset styles, but that is scoped to a media query below.
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
      padding-top: ($mdb-input-padding-base-vertical + 1); // Default padding plus a border
    }
    // Account for padding we're adding to ensure the alignment and of help text
    // and other content below items
    .radio,
    .checkbox {
      min-height: ($mdb-input-line-height-computed + ($mdb-input-padding-base-vertical + 1));
    }

    // Reset spacing and right align labels, but scope to media queries so that
    // labels on narrow viewports stack the same as a default form example.
    @media (min-width: $screen-sm-min) {
      .control-label {
        padding-top: ($mdb-input-padding-base-vertical + 1); // Default padding plus a border
      }
    }


    // Form group sizes
    //
    // Quick utility class for applying `.input-lg` and `.input-sm` styles to the
    // inputs and labels within a `.form-group`.
    .form-group-lg {
      @media (min-width: $screen-sm-min) {
        .control-label {
          padding-top: (($mdb-input-padding-large-vertical * $mdb-input-line-height-large) + 1);
          font-size: $mdb-input-font-size-large;
        }
      }
    }
    .form-group-sm {
      @media (min-width: $screen-sm-min) {
        .control-label {
          padding-top: ($mdb-input-padding-small-vertical + 1);
          font-size: $mdb-input-font-size-small;
        }
      }
    }
  }

  // INPUTS
  // label variations
  .label {
    border-radius: $border-radius-small;
    @include variations(unquote(".label"), unquote(""), background-color, $grey);
    padding: .3em .6em; // Make top & bottom .label padding the same: https://github.com/twbs/bootstrap/pull/19631
  }

  // must be broken out for reuse - webkit selector breaks firefox
  @mixin label-static($label-top, $static-font-size, $static-line-height){
    label.control-label {
      top: $label-top;
      left: 0;
      // must repeat because the selector above is more specific than the general label sizing
      font-size: $static-font-size;
      line-height: $static-line-height;
    }
  }

  @mixin label-size-variant($placeholder-font-size, $vertical-padding, $line-height, $static-font-size, $static-line-height, $help-block-font-size){
    .form-control {
      @include material-placeholder {
        font-size: $placeholder-font-size;
        line-height: $line-height;
        color: $mdb-input-placeholder-color;
        font-weight: 400;

  }
      // margin-bottom must be specified to give help-block vertical space.
      //    $see also form-group padding-bottom (and size variants) re: collapsible margins.  These work together.
      margin-bottom: $vertical-padding;
    }

    // generic labels used anywhere in the form (not control-label)
    .checkbox label,
    .radio label,
    label {
      font-size: $placeholder-font-size;
      line-height: $line-height;
      color: $mdb-input-placeholder-color;
      font-weight: 400;
    }

    // smaller focused or static size
    label.control-label {
      font-size: $static-font-size;
      line-height: $static-line-height;
      font-weight: 400;
      margin: 16px 0 0 0; // std and lg
    }

    .help-block {
      margin-top: 0; // allow the input margin to set-off the top of the help-block
      font-size: $help-block-font-size;
    }
  }

  @mixin form-group-validation-state($name, $color){

    &.#{$name} { // e.g. has-error
      .form-control {
        box-shadow: none;
      }
      &.is-focused .form-control {
        background-image: linear-gradient($color, $color), linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
      }
      label.control-label,
      .help-block {
        color: $color;
      }
    }
  }

  @mixin form-group-size-variant($parent, $placeholder-font-size, $label-top-margin, $vertical-padding, $line-height, $label-as-placeholder-shim){
    $static-font-size: ceil(($mdb-label-static-size-ratio * $placeholder-font-size)) !default;
    $static-line-height: ($mdb-label-static-size-ratio * $line-height) !default;

    $label-as-placeholder-top: -1 * ($vertical-padding + $label-as-placeholder-shim) !default;
    $label-top: $label-as-placeholder-top - ($placeholder-font-size + $vertical-padding) !default;

    $help-block-font-size: ceil(($mdb-help-block-size-ratio * $placeholder-font-size)) !default;
    $help-block-line-height: ($mdb-help-block-size-ratio * $line-height) !default;

    // this is outside a form-group
    @if not $parent {
      @include label-size-variant($placeholder-font-size, $vertical-padding, $line-height, $static-font-size, $static-line-height, $help-block-font-size);
    }

    // this is inside a form-group, may be .form-group.form-group-sm or .form-group.form-group-lg
    @else {
      #{$parent} {
        @include label-size-variant($placeholder-font-size, $vertical-padding, $line-height, $static-font-size, $static-line-height, $help-block-font-size);

        // form-group padding-bottom
        //  upon collapsing margins, the largest margin is honored which collapses the form-control margin-bottom,
        //  so the form-control margin-bottom must also be expressed as form-group padding
        padding-bottom: $vertical-padding;

        // form-group margin-top must be large enough for the label and the label's top padding since label is absolutely positioned
        margin: ($label-top-margin + $static-font-size) 0 0 0;

        // larger labels as placeholders
        &.label-floating,
        &.label-placeholder {
          label.control-label {
            top: $label-as-placeholder-top; // place the floating label to look like a placeholder with input padding
            font-size: $placeholder-font-size;
            line-height: $line-height;
          }
        }

        // static, focused, or autofill floating labels
        &.label-static,
        &.label-floating.is-focused,
        &.label-floating:not(.is-empty) {
          @include label-static($label-top, $static-font-size, $static-line-height);
        }
        // #559 Fix for webkit/chrome autofill - rule must be separate because it breaks firefox otherwise #731
        &.label-floating input.form-control:-webkit-autofill ~ label.control-label {
          @include label-static($label-top, $static-font-size, $static-line-height);
        }
      }
    }
  }

  // -----
  // Inputs
  //
  // Reference http://www.google.com/design/spec/components/text-fields.html
  // MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
  .form-control,
  .form-group .form-control {
    border: 0;
    background-image: linear-gradient($brand-primary, $brand-primary), linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;

    // Placeholders and and labels-as-placeholders should look the same
    @include material-placeholder {
      color: $mdb-input-placeholder-color;
      font-weight: 400;

  }


    //&:textarea {    // appears to be an invalid selector
    //  height: 40px;
    //}

    &[readonly],
    &[disabled],
    fieldset[disabled] & {
      background-color: rgba(0, 0, 0, 0);
    }

    &[disabled],
    fieldset[disabled] & {
      background-image: none;
      border-bottom: 1px dotted $mdb-input-underline-color;
    }
  }

  // -----
  // Labels with form-group signalled state
  //
  // Reference http://www.google.com/design/spec/components/text-fields.html
  // MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
  //.variations(unquote(" label.control-label"), color, $mdb-input-placeholder-color);  // default label color variations

  .form-group {
    position: relative;

    // -----
    // Labels with form-group signalled state
    //
    // Reference http://www.google.com/design/spec/components/text-fields.html
    // MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
    &.label-static,
    &.label-placeholder,
    &.label-floating {
      label.control-label {
        position: absolute;
        pointer-events: none;
        transition: 0.3s ease all;
      }
    }

    // hint to browser for optimization
    //    TODO: evaluate effectiveness - looking for community feedback
    &.label-floating label.control-label {
      will-change: left, top, contents;
    }

    // hide label-placeholders when the field is not empty
    &.label-placeholder:not(.is-empty){
      label.control-label{
        display: none;
      }
    }

    // Help blocks - position: absolute approach - uses no vertical space, text wrapping - not so good.
    .help-block {
      position: absolute; // do not use position: absolute because width/wrapping isn't automatic and overflows occur
      display: none;
    }

    // form-group is-focused display
    &.is-focused {
      .form-control {
        outline: none;
        background-image: linear-gradient($brand-primary, $brand-primary), linear-gradient($mdb-input-underline-color, $mdb-input-underline-color);
        background-size: 100% 2px, 100% 1px;
        box-shadow: none;
        transition-duration: 0.3s;

        .material-input:after {
          background-color: $brand-primary;
        }
      }

      //.variations(unquote(".is-focused label.control-label"), color, $brand-primary); // focused label color variations
      label,
      label.control-label {
        color: $brand-primary;
      }

      //.variations(unquote(".is-focused.label-placeholder label.control-label"), color, $mdb-input-placeholder-color);  // default label color variations
      &.label-placeholder {
        label,
        label.control-label {
          color: $mdb-input-placeholder-color;
        }
      }

      .help-block {
        display: block;
      }
    }

    @include form-group-validation-state(has-warning, $brand-warning);
    @include form-group-validation-state(has-error, $brand-danger);
    @include form-group-validation-state(has-success, $brand-success);
    @include form-group-validation-state(has-info, $brand-info);

    textarea {
      resize: none;
      & ~ .form-control-highlight {
        margin-top: -11px;
      }
    }

    select {
      appearance: none; // Fix for OS X

      & ~ .material-input:after {
        display: none;
      }
    }
  }

  // default floating size/location without a form-group (will skip form-group styles, and just render default sizing variation)
  @include form-group-size-variant(null, $mdb-input-font-size-base, $mdb-label-top-margin-base, $mdb-input-padding-base-vertical, $mdb-input-line-height-base, $mdb-label-as-placeholder-shim-base);

  // default floating size/location with a form-group (need margin etc from a default form-group)
  @include form-group-size-variant(unquote(".form-group"), $mdb-input-font-size-base, $mdb-label-top-margin-base, $mdb-input-padding-base-vertical, $mdb-input-line-height-base, $mdb-label-as-placeholder-shim-base);

  // sm floating size/location
  @include form-group-size-variant(unquote(".form-group.form-group-sm"), $mdb-input-font-size-small, $mdb-label-top-margin-small, $mdb-input-padding-small-vertical, $mdb-input-line-height-small, $mdb-label-as-placeholder-shim-small);

  // lg floating size/location
  @include form-group-size-variant(unquote(".form-group.form-group-lg"), $mdb-input-font-size-large, $mdb-label-top-margin-large, $mdb-input-padding-large-vertical, $mdb-input-line-height-large, $mdb-label-as-placeholder-shim-large);


  select.form-control {

    border: 0;
    box-shadow: none;
    border-radius: 0;

    .form-group.is-focused & {
      box-shadow: none;
      border-color: $mdb-input-underline-color;
    }

    &[multiple] {
      &,
      .form-group.is-focused & {
        height: 85px;
      }
    }
  }

  @mixin input-group-button-variation($vertical-padding){
    .input-group-btn {
      .btn {
        margin: 0 0 $vertical-padding 0;
      }
    }
  }

  // ----------------
  // input group/addon related styles

  // default margin - no form-group required
  @include input-group-button-variation($mdb-input-padding-base-vertical);

  .form-group {
    //.form-control {
    //  float: none;
    //}

    // sm margin
    &.form-group-sm {
      @include input-group-button-variation($mdb-input-padding-small-vertical);
    }

    // lg margin
    &.form-group-lg {
      @include input-group-button-variation($mdb-input-padding-large-vertical);
    }
  }

  .input-group {  // may be in or outside of form-group
    .input-group-btn {
      padding: 0 12px; // match addon spacing
    }

    .input-group-addon {
      border: 0;
      background: transparent;
    }
  }

  // Input files - hide actual input - requires specific markup in the sample.
  .form-group input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }



  .label {
      border-radius: 1px;
      //@include variations(unquote(""), background-color, $grey);
  }



  // RIPPLE
  .withripple {
      position: relative;
  }
  .ripple-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: inherit;
      pointer-events: none;
  }
  .ripple {
      position: absolute;
      width: 20px;
      height: 20px;
      margin-left: -10px;
      margin-top: -10px;
      border-radius: 100%;
      background-color: #13100d; // fallback color
      background-color: rgba(0,0,0,0.05);
      transform: scale(1);
      transform-origin: 50%;
      opacity: 0;
      pointer-events: none;
  }
  .ripple.ripple-on {
      transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
      opacity: 0.1;
  }
  .ripple.ripple-out {
      transition: opacity 0.1s linear 0s !important;
      opacity: 0;
  }


  // This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

  @mixin radio-color($color, $opacity){
    & ~ .check,
    & ~ .circle {
      opacity: $opacity;
    }

    & ~ .check {
      background-color: $color;
    }

    & ~ .circle {
      border-color: $color;
    }
  }

  .radio label, label.radio-inline {
      cursor: pointer;
      padding-left: 45px;
      position: relative;
      color: $mdb-radio-label-color;
      @include mdb-label-color-toggle-focus();
  }

  .radio, label.radio-inline {
    span {
      display: block;
      position: absolute;
      left: 10px;
      top: 2px;
      transition-duration: 0.2s;
    }
    .circle {
      border: 2px solid $mdb-radio-color-off;
      height: 15px;
      width: 15px;
      border-radius: 100%;
    }
    .check {
      height: 15px;
      width: 15px;
      border-radius: 100%;
      background-color: $mdb-radio-color-on;
      transform: scale3d(0, 0, 0);
    }
    .check:after {
      display: block;
      position: absolute;
      content: "";
      background-color: $mdb-text-color-primary;
      left: -18px;
      top: -18px;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      z-index: 1;
      opacity: 0;
      margin: 0;
      transform: scale3d(1.5, 1.5, 1);
    }

    input[type=radio]:focus:not(:checked) ~ .check:after {
      animation: rippleOff 500ms;
    }
    input[type=radio]:focus:checked ~ .check:after {
      animation: rippleOn 500ms;
    }

    input[type=radio] {
      opacity: 0;
      height: 0;
      width: 0;
      overflow: hidden;

      &:checked {
        @include radio-color($mdb-radio-color-on, 1);
      }
      &:checked ~ .check {
        transform: scale3d(0.55, 0.55, 1);
      }
    }

    input[type=radio][disabled] {

      // light theme spec: Disabled: #000000, Opacity  26%
      @include radio-color($black, 0.26);

      // dark theme spec: Disabled: #FFFFFF, Opacity  30%
      .theme-dark & {
        @include radio-color($white, 0.30);
      }
    }
  }

  @keyframes rippleOn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes rippleOff {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }

  /** RADIO **/
  // This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

  @mixin radio-color($color, $opacity){
    & ~ .check,
    & ~ .circle {
      opacity: $opacity;
    }

    & ~ .check {
      background-color: $color;
    }

    & ~ .circle {
      border-color: $color;
    }
  }

  .radio label, label.radio-inline {
      cursor: pointer;
      padding-left: 45px;
      position: relative;
      color: $mdb-radio-label-color;
      @include mdb-label-color-toggle-focus();
  }

  .radio, label.radio-inline {
    span {
      display: block;
      position: absolute;
      left: 10px;
      top: 2px;
      transition-duration: 0.2s;
    }
    .circle {
      border: 2px solid $mdb-radio-color-off;
      height: 15px;
      width: 15px;
      border-radius: 100%;
    }
    .check {
      height: 15px;
      width: 15px;
      border-radius: 100%;
      background-color: $mdb-radio-color-on;
      transform: scale3d(0, 0, 0);
    }
    .check:after {
      display: block;
      position: absolute;
      content: "";
      background-color: $mdb-text-color-primary;
      left: -18px;
      top: -18px;
      height: 50px;
      width: 50px;
      border-radius: 100%;
      z-index: 1;
      opacity: 0;
      margin: 0;
      transform: scale3d(1.5, 1.5, 1);
    }

    input[type=radio]:focus:not(:checked) ~ .check:after {
      animation: rippleOff 500ms;
    }
    input[type=radio]:focus:checked ~ .check:after {
      animation: rippleOn 500ms;
    }

    input[type=radio] {
      opacity: 0;
      height: 0;
      width: 0;
      overflow: hidden;

      &:checked {
        @include radio-color($mdb-radio-color-on, 1);
      }
      &:checked ~ .check {
        transform: scale3d(0.55, 0.55, 1);
      }
    }

    input[type=radio][disabled] {

      // light theme spec: Disabled: #000000, Opacity  26%
      @include radio-color($black, 0.26);

      // dark theme spec: Disabled: #FFFFFF, Opacity  30%
      .theme-dark & {
        @include radio-color($white, 0.30);
      }
    }
  }

  @keyframes rippleOn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes rippleOff {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }

  /** CHECKBOX **/
  // This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

  // http://www.google.com/design/spec/components/selection-controls.html#selection-controls-checkbox

  .checkbox label,
  label.checkbox-inline {
    cursor: pointer;
    padding-left: 0; // Reset for Bootstrap rule
    color: $mdb-checkbox-label-color;
    @include mdb-label-color-toggle-focus();
  }

  .checkbox,
  label.checkbox-inline {
    // Hide native checkbox
    input[type=checkbox] {
      opacity: 0;
      position: absolute;
      margin: 0;
      z-index: -1;
      width: 0;
      height: 0;
      overflow: hidden;
      left: 0;
      pointer-events: none;
    }

    .checkbox-material {
      vertical-align: middle;
      position: relative;
      top: 3px;
      &:before { // FIXME: document why this is necessary (doesn't seem to be on chrome)
        display: block;
        position: absolute;
        top:-5px;
        left: 0;
        content: "";
        background-color: rgba(0, 0, 0, .84);
        height: $mdb-checkbox-size;
        width: $mdb-checkbox-size;
        border-radius: 100%;
        z-index: 1;
        opacity: 0;
        margin: 0;
        transform: scale3d(2.3, 2.3, 1);
      }

      .check {
        position: relative;
        display: inline-block;
        width: $mdb-checkbox-size;
        height: $mdb-checkbox-size;
        border: $mdb-checkbox-border-size solid $mdb-checkbox-border-color;
        border-radius: $border-radius-base;
        overflow: hidden;
        z-index: 1;
      }
      .check:before {
        position: absolute;
        content: "";
        transform: rotate(45deg);
        display: block;
        margin-top: -4px;
        margin-left: 6px;
        width: 0;
        height: 0;
        box-shadow:
          0 0 0 0,
          0 0 0 0,
          0 0 0 0,
          0 0 0 0,
          0 0 0 0,
          0 0 0 0,
          0 0 0 0 inset;
      }
    }

    input[type=checkbox] {

      &:focus + .checkbox-material .check:after {
        opacity: 0.2;
      }

      &:focus{

        &:checked{
          & + .checkbox-material:before {
            animation: rippleOn 500ms;
          }
          & + .checkbox-material .check:before {
            animation: checkbox-on $mdb-checkbox-animation-check forwards;
          }
          & + .checkbox-material .check:after {
            animation: rippleOn $mdb-checkbox-animation-ripple forwards; // Ripple effect on check
          }
        }

        &:not(:checked) {
          & + .checkbox-material:before {
            animation: rippleOff 500ms;
          }
          & + .checkbox-material .check:before {
            animation: checkbox-off $mdb-checkbox-animation-check forwards;
          }
          & + .checkbox-material .check:after {
            animation: rippleOff $mdb-checkbox-animation-ripple forwards; // Ripple effect on uncheck
          }
        }

      }


      &:checked {

        // FIXME: once working - combine further to reduce code
        & + .checkbox-material .check {
          color: $mdb-checkbox-checked-color;
          border-color: $mdb-checkbox-checked-color;
        }

        & + .checkbox-material .check:before {
          color: $mdb-checkbox-checked-color;
          box-shadow:
            0 0 0 10px,
            10px -10px 0 10px,
            32px 0 0 20px,
            0px 32px 0 20px,
            -5px 5px 0 10px,
            20px -12px 0 11px;
        }

        & + .checkbox-material .check:after {
          //background-color: $brand-success; // FIXME: seems like tho wrong color, test and make sure it can be removed
        }
      }
    }

    // Style for disabled inputs
    fieldset[disabled] &,
    fieldset[disabled] & input[type=checkbox],
    input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check:before,
    input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check,
    input[type=checkbox][disabled] + .circle {
      opacity: 0.5;
    }
    input[type=checkbox][disabled] + .checkbox-material .check:after {
      background-color: $mdb-text-color-primary;
      transform: rotate(-45deg);
    }
  }

  @keyframes checkbox-on {
    0% {
      box-shadow:
        0 0 0 10px,
        10px -10px 0 10px,
        32px 0 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        15px 2px 0 11px;
    }
    50% {
      box-shadow:
        0 0 0 10px,
        10px -10px 0 10px,
        32px 0 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        20px 2px 0 11px;
    }
    100% {
      box-shadow:
        0 0 0 10px,
        10px -10px 0 10px,
        32px 0 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        20px -12px 0 11px;
    }
  }

  @keyframes checkbox-off {
    0% {
      box-shadow:
        0 0 0 10px,
        10px -10px 0 10px,
        32px 0 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        20px -12px 0 11px,
        0 0 0 0 inset;
    }
    25% {
      box-shadow:
        0 0 0 10px,
        10px -10px 0 10px,
        32px 0 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        20px -12px 0 11px,
        0 0 0 0 inset;
    }
    50% {
      transform: rotate(45deg);
      margin-top: -4px;
      margin-left: 6px;
      width: 0;
      height: 0;
      box-shadow:
        0 0 0 10px,
        10px -10px 0 10px,
        32px 0 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        15px 2px 0 11px,
        0 0 0 0 inset;
    }
    51% {
      transform: rotate(0deg);
      margin-top: -2px;
      margin-left: -2px;
      width: 20px;
      height: 20px;
      box-shadow:
        0 0 0 0,
        0 0 0 0,
        0 0 0 0,
        0 0 0 0,
        0 0 0 0,
        0 0 0 0,
        0px 0 0 10px inset;
    }
    100% {
      transform: rotate(0deg);
      margin-top: -2px;
      margin-left: -2px;
      width: 20px;
      height: 20px;
      box-shadow:
        0 0 0 0,
        0 0 0 0,
        0 0 0 0,
        0 0 0 0,
        0 0 0 0,
        0 0 0 0,
        0px 0 0 0 inset;
    }
  }

  @keyframes rippleOn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes rippleOff {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }







  // FIX UP THE WIDTHS
  @media (min-width: $screen-sm-min) {
    .col-sm-6 {
      width: 50%;
    }

    .col-sm-5 {
      width: 41.66667%;
    }

    .col-sm-4 {
      width: 33.33333%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-2 {
        width: 16.66667%;
    }

    .greenid_formgroup_nztadriverslicence_number {
      width: 41.66667%;
    }

    .greenid_formgroup_nztadriverslicence_versionnumber {
      margin-left: 16.66667%;
      width: 41.66667%;
    }
  }

  // OVERRIDE THE DEFAULT SHIT

  .form-group label.control-label {
    font-size: 14px;
  }

  .form-control, .form-group .form-control {
    font-weight: 700;
  }

  .form-control, .form-group .form-control {
      background-image: linear-gradient(#00677e, #00677e), linear-gradient(#13100d, #13100d);
      color: #13100d;
  }
  .form-group .form-control {
      margin-bottom: 7px;
  }
  .form-control, .form-group .form-control {
      border: 0;
      background-image: linear-gradient(#00677e, #00677e), linear-gradient(#13100d, #13100d);
      background-size: 0 2px, 100% 1px;
      background-repeat: no-repeat;
      background-position: center bottom, center calc(100% - 1px);
      background-color: transparent;
      transition: background 0s ease-out;
      float: none;
      box-shadow: none;
      border-radius: 0;
  }
  .form-group.is-empty.is-focused label.control-label {
      font-size: 14px;
      color: #666;
  }


  .form-group label.control-label {
    color: #767676;
    font-size: 14px;
  }

  .form-group .help-block {
    position: inherit;
  }

  .form-group .checkbox label, .checkbox label {
    padding-left: 30px;
    color: #13100d;
    font-size: 12px !important;
  }

  .checkbox label, label.checkbox-inline {
      cursor: pointer;
      padding-left: 0;
      color: rgba(0,0,0, 0.26);
  }
  .checkbox label, .radio label, label {
      font-size: 16px;
      line-height: 1.42857;
      color: #BDBDBD;
      font-weight: 400;
  }
  .radio label, .checkbox label {
      min-height: 22px;
  }
  .radio label, .checkbox label {
      min-height: 20px;
      padding-left: 30px;
      margin-bottom: 0;
      font-weight: normal;
      cursor: pointer;
  }
  label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: bold;
  }

  // FIX CHECKBOX
  .checkbox .checkbox-material .check, label.checkbox-inline .checkbox-material .check {
    border-radius: 0;
  }


}


/*Wiliam hack*/
#greenid-container {
    .checkbox input[type=checkbox],
    label.checkbox-inline input[type=checkbox] { opacity: 1; z-index: 1; width: auto; height: auto; margin: 6px; }
    .checkbox label{ color:#13100d; }

    .form-group{
        padding-bottom: 0;
        margin: 0 0 0 0;
    }

    .btn-primary { background-color: #00677e; border-color: #00636a; border-radius: 30px;   font-size: 18px; line-height: 1.33333; padding: 10px 36px; }
    .btn-primary:hover { background-color: #003d4b; border-color: #002a2c; }

    .checkbox.greenid-has-error .help-block {
      padding-left: 30px;
    }

    // MODAL
    .greenid-modal {
      margin-top: 120px;

      .modal-content {
        border-radius: 0;
      }
    }

    #greenid-source-content {
      min-height: 488px !important;
    }
}
