@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/iconmoon/icomoon.eot?7f4rgo');
    src:    url('../fonts/iconmoon/icomoon.eot?7f4rgo#iefix') format('embedded-opentype'),
        url('../fonts/iconmoon/icomoon.ttf?7f4rgo') format('truetype'),
        url('../fonts/iconmoon/icomoon.woff?7f4rgo') format('woff'),
        url('../fonts/iconmoon/icomoon.svg?7f4rgo#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-right:before {
  content: "\e91c";
}
.icon-arrow-down:before {
    content: "\e900";
}
.icon-arrow-down-sign:before {
    content: "\e901";
}
.icon-bill-payment:before {
    content: "\e902";
}
.icon-check:before {
    content: "\e903";
}
.icon-check-circle:before {
    content: "\e904";
}
.icon-chevron-down:before {
    content: "\e905";
}
.icon-cog:before {
    content: "\e906";
}
.icon-credit-card:before {
    content: "\e907";
}
.icon-credit-card-lined:before {
    content: "\e908";
}
.icon-exclamation:before {
    content: "\e909";
}
.icon-exclamation-sign:before {
    content: "\e90a";
}
.icon-exit:before {
    content: "\e90b";
}
.icon-group:before {
    content: "\e90c";
}
.icon-group-sign:before {
    content: "\e90d";
}
.icon-info:before {
    content: "\e90e";
}
.icon-info-sign:before {
    content: "\e90f";
}
.icon-money:before {
    content: "\e910";
}
.icon-print:before {
    content: "\e911";
}
.icon-print-sign:before {
    content: "\e912";
}
.icon-question:before {
    content: "\e913";
}
.icon-question-sign:before {
    content: "\e914";
}
.icon-remove:before {
    content: "\e915";
}
.icon-remove-bold:before {
    content: "\e916";
}
.icon-transaction:before {
    content: "\e917";
}
.icon-transfer:before {
    content: "\e918";
}
.icon-transfer-2:before {
    content: "\e919";
}
.icon-user:before {
    content: "\e91a";
}
.icon-user-sign:before {
    content: "\e91b";
}

