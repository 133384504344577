﻿// https://dvcs.w3.org/hg/FXTF/raw-file/tip/filters/index.html
//
// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);

@mixin filter($filter-type,$filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}


// border-radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

// box-shadow
@mixin box-shadow($top, $left, $blur, $color) {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
}

// text-shadow
@mixin text-shadow($x-coordinate,$y-coordinate,$blur,$color) {
    -webkit-text-shadow: $x-coordinate $y-coordinate  $blur  $color;
    -moz-text-shadow: $x-coordinate $y-coordinate  $blur  $color;
    text-shadow: $x-coordinate $y-coordinate  $blur  $color;
}

/*transition*/
@mixin transition($property, $duration) {
    transition: $property $duration cubic-bezier(.8,0,.2,1) 0s;
    -moz-transition: $property $duration cubic-bezier(.8,0,.2,1) 0s; /* Firefox 4 */
    -webkit-transition: $property $duration cubic-bezier(.8,0,.2,1) 0s; /* Safari and Chrome */
    -o-transition: $property $duration cubic-bezier(.8,0,.2,1) 0s; /* Opera */
}

/*transition*/
@mixin transition-standard() {
    transition: all 0.33 cubic-bezier(.8,0,.2,1) 0s;
    -moz-transition: all 0.33 cubic-bezier(.8,0,.2,1) 0s; /* Firefox 4 */
    -webkit-transition: all 0.33 cubic-bezier(.8,0,.2,1) 0s; /* Safari and Chrome */
    -o-transition: all 0.33 cubic-bezier(.8,0,.2,1) 0s; /* Opera */
}
// placeholder
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}








//* ************************************* */
//* SASS Mixins */ http://codepen.io/KurtWM/pen/xrtEv
//* ************************************* */

//* webkit animation syntax: 'animation: name duration timing-function delay iteration-count direction;' */
@mixin animation($name: none, $duration: 0, $timing-function: ease, $delay: 0, $iteration-count: 1, $direction: normal) {
  -webkit-animation: $name $duration $timing-function $delay $iteration-count $direction;
  -moz-animation: $name $duration $timing-function $delay $iteration-count $direction;
  -ms-animation: $name $duration $timing-function $delay $iteration-count $direction;
  -o-animation: $name $duration $timing-function $delay $iteration-count $direction;
  animation: $name $duration $timing-function $delay $iteration-count $direction;
}

@mixin transform($translate-x: 0, $translate-y: 0, $rotate-deg: 0, $scale-x: 1, $scale-y: 1, $skew-x: 0, $skew-y: 0) {
  -webkit-transform: translate($translate-x, $translate-y) rotate($rotate-deg) scale($scale-x, $scale-y) skew($skew-x, $skew-y);
  -ms-transform: translate($translate-x, $translate-y) rotate($rotate-deg) scale($scale-x, $scale-y) skew($skew-x, $skew-y);
  transform: translate($translate-x, $translate-y) rotate($rotate-deg) scale($scale-x, $scale-y) skew($skew-x, $skew-y);
}

@mixin transform-3d($translate-x: 0, $translate-y: 0, $translate-z: 0, $rotate-x: 0, $rotate-y: 0, $rotate-z: 0, $scale-x: 1, $scale-y: 1, $scale-z: 1, $perspective: none) {
  -webkit-transform:
    translate3d($translate-x, $translate-y, $translate-z)
    rotateX($rotate-x) rotateY($rotate-y) rotateZ($rotate-z)
    scaleX($scale-x) scaleY($scale-y) scaleZ($scale-z)
    skew($skew-x, $skew-y)
    perspective($perspective);
  -ms-transform:
    translate3d($translate-x, $translate-y, $translate-z)
    rotateX($rotate-x) rotateY($rotate-y) rotateZ($rotate-z)
    scaleX($scale-x) scaleY($scale-y) scaleZ($scale-z)
    skew($skew-x, $skew-y);
  transform:
    translate3d($translate-x, $translate-y, $translate-z)
    rotateX($rotate-x) rotateY($rotate-y) rotateZ($rotate-z)
    scaleX($scale-x) scaleY($scale-y) scaleZ($scale-z)
    skew($skew-x, $skew-y)
    perspective($perspective);
}

@mixin translate(
  $translate-x: 0,
  $translate-y: 0) {
  -webkit-transform: translate($translate-x, $translate-y);
  -ms-transform: translate($translate-x, $translate-y);
  transform: translate($translate-x, $translate-y);
}

@mixin translate3d(
  $translate-x: 0,
  $translate-y: 0,
  $translate-z: 0) {
  -webkit-transform: translate3d($translate-x, $translate-y, $translate-z);
  -ms-transform: translate3d($translate-x, $translate-y, $translate-z);
  transform: translate3d($translate-x, $translate-y, $translate-z);
}
@mixin center {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position:absolute;
}
@mixin center-vertical {
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  position:absolute;
}
@mixin center-horizontal {
  -webkit-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  transform: translate(-50%,0);
  margin-left: 50%;
}
@mixin remove-translate {
  -webkit-transform: translate(0,0);
  -ms-transform: translate(0,0);
  transform: translate(0,0);
  margin-left: 0;
  top:0;
  left:0;
  position:relative;
}


@mixin scale(
  $scale-x: 1,
  $scale-y: 1) {
  -webkit-transform: scale($scale-x, $scale-y);
  -ms-transform: scale($scale-x, $scale-y);
  transform: scale($scale-x, $scale-y);
}

@mixin skew(
  $skew-x: 0,
  $skew-y: 0) {
  -webkit-transform: skew($skew-x, $skew-y);
  -ms-transform: skew($skew-x, $skew-y);
  transform: skew($skew-x, $skew-y);
}

@mixin rotate(
  $rotate-deg: 0) {
  -webkit-transform: rotate($rotate-deg);
  transform: rotate($rotate-deg);
}

@mixin transform-origin(
  $origin-x-axis: 50%,
  $origin-y-axis: 50%,
  $origin-z-axis: 0) {
  -webkit-transform-origin:$origin-x-axis $origin-y-axis $origin-z-axis;
  -ms-transform-origin:$origin-x-axis $origin-y-axis $origin-z-axis;
  transform-origin:$origin-x-axis $origin-y-axis $origin-z-axis;
}

@mixin transform-style($style: flat) {
  -webkit-transform-style: $style;
  -ms-transform-style: $style;
  transform-style: $style;
}

@mixin opacity($opacity: 1) {
  zoom: 1;
  filter: alpha(opacity=$opacity * 100);
  opacity: ($opacity * 1.0);
}

@mixin shadow($offsetX, $offsetY, $blur, $color) {
  -webkit-filter: drop-shadow($offsetX+px $offsetY+px $blur+px $color);
  filter: url(#drop-shadow);
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=" + $offsetX + ", OffY=" + $offsetY + ", Color='" + $color + "')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=" + $offsetX + ", OffY=" + $offsetY + ", Color='" + $color + "')";
}

@mixin perspective($perspective: none) {
  -webkit-perspective: $perspective;
  perspective: $perspective;
}



.exemple {
    @include transition(all, 0.3s);
    @include border-radius(3px);
    @include placeholder {
    font-style:italic;
    color: white;
    font-weight:100;
    }
}
.exemple{@include translate(-260px,0);}
.exemple{@include translate3d(-260px,0,0);}
.exemple{@include center-vertical;}
.exemple{@include center-horizontal;}
