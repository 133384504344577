﻿
.selectTransferMethod { border: 2px solid #ddd; height: 270px; background-color: #fff; border-top: 3px solid #000;
                         padding: 20px; cursor: pointer; display: table-cell; vertical-align: bottom; }

.selectTransferMethod .selector{ position:absolute; bottom:0; right:0; }
.selectTransferMethod .selector label { }
.selectTransferMethod .type-icon{ position:absolute; top:30px; left:20px;}
.selectTransferMethod.selected{ border:2px solid $brand-primary; border-top-width:3px;}

.currency-unselected { padding-bottom: 60px; }

.currency-selected { display: none; }

.currency-transfer-type { display: none; }

.selectablable-hide-non-selected { }

.visible-only-selected { display: none; }
.selectable.selected .visible-only-selected { display: block; }
.show-selected-transfer-method{ top:-2px; left:-2px; margin-right:2px; margin-bottom:2px; }


.security-questions {
  background-color: #f9f9f9;
  border: #e5e5e5 solid 1px;
  padding: 4px 20px;
}

@media (max-width: $screen-xs-max) {
   .selectTransferMethod{ display:block; margin-bottom:10px;  height:auto; padding:15px; padding-left:105px; min-height:135px; }
   .selectTransferMethod .type-icon{ display:block; width:70px; top:20px;}
   .selectTransferMethod .type-icon img{width:100%; }
   .selectTransferMethod.currency-to-currency .type-icon img { margin-left:8px; width:50px; }

}
