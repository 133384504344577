.additional-currency {
  display: none;
}

.additional-currency.shown {
  display: block;
}

/*currency-selector*/
.currency-selector-container{ margin-left:-20px; margin-right:-20px; }
@media (max-width: 820px) {
    .currency-selector-container{ margin-left:0; margin-right:0; }
}
/* height:130px; */
.currency-selector{ border:2px solid #ddd;  padding:15px; margin-bottom:10px; float:left; cursor:pointer; }
.currency-selector.selected{ border-color:$brand-primary; }
.currency-selector:hover{ border-color:#999; }
.currency-selector .selector { position:absolute; right:0; bottom:0;}
.currency-selector .flag{ width:34px; height:34px;}
.currency-selector h2 { margin-bottom: 0; }


.currency-selector {
  h6 {
    margin: 0 0 20px 0;
  }

  h2 {
    line-height: 1;
  }

  p {
    padding-bottom: 9px;
  }

  @media (max-width: $screen-xs-max) {
    h6 {
      margin: 0 0 10px 0;
    }

    .media {
      margin: 0 0 0 0 !important;
    }

    h2 {
      line-height: 1;
    }

    p {
      padding-bottom: 0 ;
    }
  }
}



/*selector*/
.selector input[type="radio"]{display:none;}
.selector input[type="checkbox"] {
    display:none !important;
}
.selector input[type="checkbox"] + label{ padding-left:28px; cursor:pointer; color:#000;}

.selector input[type="checkbox"] + label i,
.selector input[type="radio"] + label i {
    display:inline-block;
    width:28px;
    height:28px;
    margin:0;
    vertical-align:middle;
    border:none;
    cursor:pointer;
    color:#fff;
    border:2px solid #ddd;
    position:absolute;
    left:0;
    top:0;
    display:none;
    font-size:12px;
}
.selector input[type="checkbox"]:checked + label i,
.selector input[type="radio"]:checked + label i{
    background-color:$brand-primary;
    border-color:$brand-primary;
    display:block;
}
.selector label{ margin-bottom:0; width:28px; height:28px; display:block;}
.selector input[type="checkbox"]:checked + label i,
.selector input[type="radio"]:checked + label i{
    display:block;
}
.selector input[type="checkbox"]:checked + label i:before,
.selector input[type="radio"]:checked + label i:before{
    position:absolute;
    top:6px;
    left:6px;
}


@media (max-width: $screen-xs-max) {
    .currency-selector{ float:none; padding-bottom:30px;  }
    .currency-selector .media{ overflow:visible !important;}
    .currency-selector .media-right{ margin-top:-5px; }
    .currency-selector .flag{ width:25px; height:25px; }
    .currency-selector h6 { height:30px; }

}
