

/* Clear Floated Elements
----------------------------------------------------------------------------------------------------*/
.clear { clear: both; display: block; overflow: hidden; visibility: hidden; width: 0; height: 0; }
.clearfix:before,
.clearfix:after { content: '\0020'; display: block; overflow: hidden; visibility: hidden; width: 0; height: 0; }
.clearfix:after { clear: both;}
/*
  The following zoom:1 rule is specifically for IE6 + IE7.
  Move to separate stylesheet if invalid CSS is a problem.
*/
.clearfix { zoom: 1; }

/*Layout*/
.inner{ float:left; width:100%;}
.inline{ display:inline;}

.section,.block{
    float:left; width:100%; display:block;
    @include transition(all, 0.5s);
}
.section:before, .section:after,
.block:before, .block:after,
.paper:before, .paper:after {
content: ' ';
display: table;
}
.section:after,
.block:after,
.paper:after{ clear: both;}

.paper{ max-width: 870px; background-color:#fff; margin-left:auto; margin-right:auto; padding-top:0; z-index: 2; padding-bottom:40px;  }
.section{overflow-x:hidden;}
.section.section-without-steps{ padding-top:60px; }


/*paragraph*/
.two-col {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
}
.three-col {
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
}

/*colmns*/
.col,
.left-col,
.right-col{ display: inline; float: left; position: relative; margin-left: 0; margin-right: 0; }
.col.d1 { width: 100%; }
.col.d2 { width: 50%; *width: 49.9%; }
.col.d3 { width: 33.3%; *width: 33.3%; }
.col.d4 { width: 25%; *width: 24.9%; }
.col.d5 { width: 20%; *width: 19.9%; }
.col.col-25 { width: 25%; *width: 24.9%; }
.col.col-30 { width: 30%; *width: 29.9%; }
.col.col-33 { width: 33.33%; *width: 33.2%; }
.col.col-50 { width: 50%; *width: 49.8%; }
.col.col-75 { width: 75%; *width: 74.9%; }
.col.col-100 { width: 100%; *width: 100%; }


/*Margins*/
.mt10{ margin-top:10px;}
.mb10{ margin-bottom:10px;}
.mt20{ margin-top:20px;}
.mb20{ margin-bottom:20px;}
.mt30{ margin-top:30px;}
.mb30{ margin-bottom:30px;}

.mr10 { margin-right: 10px; }
.mr20 { margin-right: 20px; }
.mr30 { margin-right: 30px; }

.standard-mt{ margin-top:20px; }
.standard-mb{ margin-bottom:20px; }

/* Paddings */
.pt10 {
  padding-top: 10px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr30 {
  padding-right: 30px !important;
}



/*form layout*/
/*.form-row{
     width:100%; display:block; float:left;
}
.form-row:before, .form-row:after{
content: ' ';
display: table;
}
.form-row.form-inline{ margin-right:-10px; width:calc(100% + 10px);}
.form-inline .form-group{ float:left;}
.form-inline .form-control{ display:block; width:100%;}
.form-inline.address-row .form-group:first-child{ width:calc(100% - 48px); }

.form-inline .inp-inline{ display:inline; float:left; padding: 0 10px 0 0; margin-top:0; }
.form-inline .inp-d15 { width: 15%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d16 { width: 16.66%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d20 { width: 20%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d22 { width: 22%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d25 { width: 25%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d26 { width: 26%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d28 { width: 28%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d30 { width: 30%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d33 { width: 33.333%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d35 { width: 35%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d40 { width: 40%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d45 { width: 45%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d50 { width: 50%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d60 { width: 60%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d75 { width: 75%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d85 { width: 85%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d88 { width: 88%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d90 { width: 90%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d91 { width: 91%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d100 { width: 100%; float: left; padding: 0 10px 0 0; }
.form-inline .inp-d100 .inp-d100 { padding-right:0;}*/

#site-nav-secondary {
  display: none;
}



/*------------------------------------------------------------------Mobile*/
@media (max-width: 600px) {
    /*paragraph*/
    .two-col {
        -moz-column-count: 1;
        -moz-column-gap: 20px;
        -webkit-column-count: 1;
        -webkit-column-gap: 20px;
    }
    .three-col {
        -moz-column-count: 1;
        -moz-column-gap: 20px;
        -webkit-column-count: 1;
        -webkit-column-gap: 20px;
    }


}



/*------------------------------------------------------------------Mobile*/
@media (max-width: $screen-xs-max) {
    .section,.block{ float:none;}
    .container{ padding-left:20px; padding-right:20px; }
    .paper{ padding-bottom:20px;  }
    .section.section-without-steps{ padding-top:20px; }


    /*Margins*/
    .mt10-xs{ margin-top:10px;}
    .mb10-xs{ margin-bottom:10px;}
    .mt20-xs{ margin-top:20px;}
    .mb20-xs{ margin-bottom:20px;}
    .mt30-xs{ margin-top:30px;}
    .mb30-xs{ margin-bottom:30px;}

    .standard-mt{ margin-top:10px; }
    .standard-mb{ margin-bottom:10px; }

    .text-right-mobile {
      text-align: right;
    }
    .mobile-mb10 {
      margin-bottom: 10px !important;
    }
    .mobile-mt5 {
      margin-top: 5px !important;
    }
    .mobile-mt10 {
      margin-top: 10px !important;
    }

    .mobile-mb15 {
      margin-bottom: 15px !important;
    }
    .mobile-mb50 {
      margin-bottom: 50px !important;
    }

    .mobile-pt10 {
      padding-top: 10px !important;
    }

    .mobile-pt20 {
      padding-top: 20px !important;
    }

    .mobile-pb20 {
      padding-bottom: 20px !important;
    }
}
