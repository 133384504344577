.select-payment-method {
  cursor: pointer;
  display:block; 
  float:left;
  width:100%;
}
.select-payment-method .inner {
  border:2px solid #ddd;
  padding:20px 15px 30px;
  text-align:center;
  height:155px;
}
.select-payment-method:hover .inner {
  border-color:#999;
}
.select-payment-method.selected .inner{
    border-color:$brand-primary;
}

.payment-type-info {
  min-height: 60px;
  font-size: 14px;
}

.select-payment-method h6 {
  font-weight:700;
  font-size:18px;
}
.select-payment-method .icon-wrap {
  margin-top:10px;
  margin-bottom:5px;
  height:20px;
}
.select-payment-method .small {
  font-size:14px;
  color:#777;
}
.select-payment-method .glyphicon {
    font-size:20px;
}
.select-payment-method .icon-transfer{ font-size:20px;}
.select-payment-method .icon-bill-payment{ font-size:20px;}


.select-payment-method .selector {
  position: absolute;
  bottom: 0;
  right: 0;
}


.payment-method {
  display: none;
}

.payment-method.active {
  display: block;
}


.payment-method-selected {
  display: none;
}


#load-navigation-progress {
  .payment-button-options {
    display: none;
  }
}

#load-navigation-progress.select-method {
  .payment-method-required {
    display: block;
  }
}

#load-navigation-progress.make-payment {
  .payment-method-action {
    display: block;
  }
}

#load-navigation-progress.completed {
  .payment-method-completed {
    display: block;
  }
}


.load-totals-bottom {
  margin-bottom: 12px;
}

#top-up-details .visible-unless-paid {
  display: block;
}

#top-up-details.paid .visible-unless-paid {
  display: none;
}



#top-up-details .visible-when-paid {
  display: none;
}

#top-up-details.paid .visible-when-paid {
  display: block;
}


/*payment-total-aside*/
.payment-total-col{ padding-left:20px;}
.payment-total-aside{ padding:10px 20px; background:#eee; }
.payment-total-aside .breakdown-table{ width:100%;}
.payment-total-aside .breakdown-table .total-row td{ border-bottom:none;}
.payment-total-aside .confirm_payment_type{ margin-top:0; }

/*breakdown-table*/
.breakdown-table td{ padding:10px 0; border-bottom:1px solid #ccc; }
.breakdown-table td:last-child{ text-align:right; }
.breakdown-table .total-row{ font-size:24px; font-weight:700; }
.breakdown-table .curr-sign{ font-size:21px;}
.breakdown-table .curr{ font-size:14px;}

.confirm_payment_type {
  margin-top: 10px;
  label {
    color: black;
  }
}


@media (max-width: $screen-xs-max) {
   .select-payment-method{ margin-bottom:10px; float:left; width:100%;}
   .select-payment-method .icon-wrap{ position:absolute; left:20px; top:20px; margin:0; }
   .select-payment-method .inner{ padding-bottom:0; padding-left:60px; padding-top:15px;  text-align:left; height:auto;  }

   /*payment-total-aside*/
    .payment-total-col{ padding-left:5px; }
}

