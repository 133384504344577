
/*hr*/
hr{ margin-top:30px; margin-bottom:30px; }
hr.hr-double-dot{ border-top: 1px dotted #888;  }
hr.hr-double-dot:after{
    content: '';
	display: block;
	margin-top: 2px;
	border-top: 1px dotted #888;  }
hr.hr-dot{border-top: 1px dotted #888;}
hr.hr-line{ border-top: 1px solid #ccc;}
hr.hr-line-bold{ border-top: 2px solid #13100d;}
hr.no-margin{ margin:0;}


.border-top-line:before,
.border-bottom-line:after{ content:""; display:block; border-top:#ddd solid 1px; width:100%; float:left;  }


.border-top-line.row:before,
.border-bottom-line.row:after{ content:""; display:block; border-top:#ddd solid 1px; width:calc(100% - 10px);left:5px; float:left;   }

.border-top-line.strong:before,
.border-bottom-line.strong:after{ border-color:#13100d; border-width:2px;}

.border-top-dot.row:before,
.border-bottom-dot.row:after{ content:""; display:block; border-top:#888 dotted 1px; width:calc(100% - 10px);left:5px; float:left; }

.border-right-dot { border-right:1px dotted #888; padding-right:20px;}
.border-left-dot { border-left:1px dotted #888; padding-left:20px;}



/*definition list*/
.dl-horizontal dt{ width:auto; }
.dl-horizontal dd{ margin-left:auto;}


/*alert*/
.alert-custom { padding:15px 0;  padding-left:30px;}
.alert-custom.lined{  border-top:dotted 1px #888; margin-bottom:20px; }
.alert-custom .alert-icon{ position:absolute; left:5px; top:18px;}
.alert-custom.alert-no-margin { margin-bottom: 0;}
.alert-custom.no-padding-top { padding-top:0;}
.alert-custom.no-padding-top .alert-icon{ top:3px;}
.alert-custom .glyphicon-warning-sign{ color:$brand-danger;}
.alert-custom-icon-print-right .icon-print{ float:right; color:#13100d; font-weight:700; }
.alert-custom .glyphicon-ok-circle{ color:$brand-success;}
.alert-custom.alert-no-border-bottom { border-bottom:none; margin-bottom:0; }


/*pagination*/
.pagination{ float:right;}
.pagination > li{ display:inline-block; float:left; margin-left:6px; margin-right:6px; }
.pagination > li:first-child {border-right:1px solid #13100d;  }
.pagination > li:last-child {border-left:1px solid #13100d;  }
.pagination > li > a { border:none; color:#13100d;  margin:0;  }
.pagination > li:first-child > a { border:none;  }


/*checkbox*/
.checkbox.small label { font-size: 12px; }
.form-group .checkbox label,
.checkbox label{ padding-left:30px; color:#13100d;}
.checkbox .checkbox-material{ position:absolute; left:0; top:1px;}


/*form*/
.form-group{ margin:17px 0 0 0; }
.form-group .description-text .glyphicon { margin-right:5px; top:2px;}
.form-control, .form-group .form-control{ font-weight:700;  }
.form-group select.form-control{ padding-left:0; -webkit-appearance: none; }
.form-group .dropdown:before{ content:""; display:block; width:10px; height:8px; background-image:url(../images/svg/chevron-down.svg); background-repeat:no-repeat; background-size:contain; top:17px; right:5px; position:absolute; opacity:0.4;  }
.form-group label.control-label{ font-size:14px;}




/* COLOR CODED THINGS */

.activeColor {
  color: green;
}

.inactiveColor {
  color: red;
}

/* BUTTONS */
.btn {
  border-radius: 30px;
  @include box-shadow( 1px 1px 1px rgba(0, 0, 0, 0.3));
  padding:6px 23px;
}
.btn-link{ color:#13100d; text-decoration:underline; font-weight:700; @include box-shadow( 1px 1px 1px rgba(0, 0, 0, 0));}


.btn.btn-square {
  border-radius: 0;
}

.btn.btn-lg {
  padding: 10px 36px;
}

.btn-link + .btn-lg {
  margin-left: 18px;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    background-color: #dddddd !important;
    border:none;
    color:#616465;
    @include box-shadow( 1px 1px 1px rgba(0, 0, 0, .3))
}


/* others */
.dark-border-top {
  border-top: 2px solid black;
}

.dark-border-bottom {
  border-bottom: 2px solid black;
}



/*.small-description-text {
  font-size: 12px;
}*/

.primary-color-bordered {
  border: 1px solid $brand-primary;
}
.padding-xs-10 {
  padding: 6px 0;
}
.std-padding {
  padding: 18px 0 0 0;
}



/*accordion*/
.accordion .accordion-toggle { cursor:pointer; float:right; font-size:12px; }
.accordion.open .accordion-toggle { @include rotate(180deg); }
.accordion .accordion-content{ display:none; }
.accordion.open .accordion-content{ display:block; }







@media (max-width: $screen-xs-max) {
    hr{ margin-top:20px; margin-bottom:20px; }
    .xs-no-border{ border:none;}
    .xs-no-border:before,
    .xs-no-border:after{ display:none;}
    .border-right-dot.xs-no-border{ padding-right:5px;}

    .alert-custom-icon-print-right .icon-print{ display:none; }

    /*Modals*/
    .modal--login .modal-content{ padding:20px; }

    /*Bottons*/
    .btn.btn-lg {
      padding: 8px 36px;
      font-size: 16px;
    }
}




















