/*transfer-form*/
.transfer-form{ float:left; width:100%;}
.transfer-form .item-transfer-form .inner{ padding-top:20px; padding-bottom:20px;}
.transfer-form .col-curr,
.transfer-form .col-amount{ width:30%; }
.transfer-form .col-equal-sign{
    width:10%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    display: inline-block; }

.transfer-form .col-curr .balance{ font-size:14px; color:#666;}
.transfer-form .col-curr .curr{ margin-bottom:0; font-size:40px;}
.transfer-form .col-amount{ font-size:34px; text-align:right; font-weight:700; padding-top:18px; }
.transfer-form .curr-sign{ font-size:18px; padding-right:5px; }
.transfer-form .decimal{ font-size:18px; }
.transfer-form .curr{ font-size:18px; }
.transfer-form .rate{ font-size:12px; color:#666; font-weight:normal;}
.transfer-form .col-equal-sign .equal-sign{ top:28px;}
.transfer-form .remove-container{ position:absolute; right:0; top:15px; cursor:pointer; color:$brand-primary; opacity:.8;}
.transfer-form .remove-container:hover{ opacity:1;}

.transfer-form .col-amount .input-group-addon { position:absolute; top:18px; padding:0; font-weight:700; font-size:21px; color:#000;}
.transfer-form .col-amount .input-group-addon.curr-sign { left:0;}
.transfer-form .col-amount .input-group-addon.curr{ right:0; width:33px; font-size:14px; top:24px; } 
.transfer-form .col-amount .form-group{ margin-top:0;}
.transfer-form .col-amount .form-control {
    font-size: 34px;
    font-weight: 700;
    color: #000;
    padding-left: 23px;
    padding-right: 38px;
    text-align: right;
    padding-bottom:16px;
}
.transfer-form .col-amount .form-control.long-currency-display {
    padding-bottom:0;
} 


.transfer-form.details{ margin-top:0;}
.transfer-form.details .col-amount{ padding-top:19px; }

.hide-balance .transfer-form .balance{ display:none;}

.transfer-form .item-transfer-form-th{ padding-top:10px;}
.transfer-form .item-transfer-form-th h6{ font-size:14px; font-weight:700;}
.transfer-form .item-transfer-form-th .inner{ padding-bottom:0; padding-top:0;}
.transfer-form .item-transfer-form-th .col-amount{ padding-top:0;}

.transfer-form.currencyTotals{ margin-top: 0;}
.transfer-form.currencyTotals .order-total{ }
.transfer-form.currencyTotals{ font-weight:700;}
.transfer-form.currencyTotals .inner{ padding-top:10px; padding-bottom:10px;}
.transfer-form.currencyTotals .right-col{ text-align:right; }
.transfer-form.currencyTotals .curr-sign{ font-size:21px; }
.transfer-form.currencyTotals .col-number{ margin-left:20px;}
.transfer-form.currencyTotals .prevTab { margin-top: 17px;}

.transfer-form .breakdown-table { min-width:250px; float:right;}
.transfer-form .total-row{ font-size:34px; font-weight:700; text-align:right; display:block; float:left; width:100%; padding-top:10px; }


.input-group.currency-focused {
  input, .curr-sign, .curr {
    color: #00677e !important;
  }
}

.long-currency-display {
  font-size: 14px !important;
}

@media (max-width: $screen-xs-max) {
    .transfer-form .item-transfer-form-th{ display:none; }
    .transfer-form .col-curr .curr{ font-size:21px;}
    .transfer-form .curr-sign,
    .transfer-form .curr{ font-size:14px;}
    .transfer-form .col-amount{ font-size:18px; padding-top:10px; }
    .transfer-form .col-amount .form-group{ margin-top:0; }
    .transfer-form .col-amount .input-group{ width:100%; }
    .transfer-form .col-curr{ width:100%;}
    .transfer-form .col-amount{ width:45%;}
    .transfer-form .col-equal-sign{ width:10%;}
    .transfer-form .col-curr .balance{ margin-top:0px; font-size:12px;}
    .transfer-form .item-transfer-form .inner{ border-top:1px solid #ccc; padding-top:10px; padding-bottom: 10px;}
    .transfer-form .col-equal-sign .equal-sign{ top:12px;}
    .transfer-form .breakdown-table{ width:100%;}
    .transfer-form .total-row{ font-size:21px; padding-top:10px;}
    .transfer-form .decimal{ font-size:14px;}
    .transfer-form.details .col-amount{ padding-top:10px; }
    .transfer-form.details .col-equal-sign .equal-sign{ top:8px;}

    .transfer-form .col-amount .input-group-addon { font-size:16px; top:12px; }
    .transfer-form .col-amount .form-control{ font-size:12px; padding-left:16px; padding-right:35px; padding-bottom:6px; }
    .transfer-form .col-amount .form-control.long-currency-display{ padding-bottom:7px; }
    .transfer-form .col-amount .rate{ margin-top: 0;  white-space: nowrap; text-align: right; float: right; width:100%; font-size:12px;}
    .transfer-form .col-amount .input-group-addon.curr{ text-align:right; font-size:14px; width:30px; top:13px; }

    .transfer-form.currencyTotals .order-total{ font-size:21px; }
    .transfer-form.currencyTotals .curr-sign{ font-size:16px; margin-top:3px; }

    .order-total-sign { float:left; margin-top:5px; }
    
}
