/*masthead*/
.masthead { margin-left: auto; margin-right: auto; max-width: 1230px; z-index: 1020; }
.masthead a { text-decoration: none; }
.masthead:before { content: ""; display: block; position: absolute; max-width: 870px; background-color: #fff; margin-left: auto; margin-right: auto; height: 50px; }
.masthead .nav { float: right; }
.nav-pills > li { min-width: 125px; } 
.masthead .block-1 .content { float: right; }
.masthead .block-1 .content .welcome-message { float: left; padding-top: 15px; padding-right: 20px; padding-left: 20px;   height: 50px; background-color: #fff; }

.masthead .block-1 .nav-pills > li > a {
    height: 50px;
    padding: 15px;
    padding-right: 45px;
    color: #000;
    background-color: #f4f4f4;
    font-weight:bold;
    @include border-radius(0);
}

.masthead .block-1 .nav-pills > li > a .icon { height: 25px; width: 25px; margin-left: 5px; position: absolute; top: 13px; right: 10px; font-size:25px; }
.masthead .block-1 .nav-pills > li > a .icon-exit { top: 15px; font-size:20px;  }
.masthead .block-1 .nav-pills > li > a:hover{ background-color:#eee; }
.masthead .block-2 { background-color: #000;}
.masthead .navbar { border: none; }
.masthead .block-2:after{
    content:"";
    position:absolute;
    z-index:-1;
    width:calc(100% - 20px);
    height:10px;
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:60px;
    bottom:0;
    left:10px;
    right:10px;
    border-radius:100px / 10px;}
.masthead .navbar-brand { padding: 18px 40px; }
.masthead .navbar-brand .logo { width: 180px; }
.masthead .navbar-collapse { padding: 0; }

.masthead .navbar-nav > li > a { color: #fff; width: 100%; padding-left: 0; padding-right: 0; padding-top: 25px; color: #fff; font-size: 16px; font-weight: 700; height: 70px; text-align: center; }
.masthead .navbar-nav > li.active > a { background-color:#0099a3; }
.masthead .navbar-nav > li > a:before { content: ""; display: block; height: 20px; width: 1px; background-color: #333; position: absolute; top: 25px; }
.masthead .navbar-nav > li:first-child > a::before { display: none; }
.masthead .navbar-nav > li > a:hover { background: transparent; }
.masthead .navbar-nav > li > a:hover::after { content: ""; display: block; width: 100%; height: 10px; background-color:#0099a3; position: absolute; bottom: 0; left: 0; }
.masthead .navbar-nav > li.active > a:before { display:none; }


// Old code:
.masthead .navbar-nav { width: calc(100% - 270px); display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: nowrap; flex-wrap: nowrap; -ms-flex-pack: distribute; justify-content: space-around; }
.masthead .navbar-nav > li { display: inline-block; -webkit-box-flex: 1; -ms-flex: 1 1 auto; flex: 1 1 auto; }

// Added for IE9 compatibility. Items will not be even.

    .masthead .navbar-nav { display: table; }
    .masthead .navbar-nav > li { display: table-cell; }
    // Overwrite bootstrap float
    @media (min-width: 768px)
    {
        .navbar-nav > li {
            float: none;
        }
    }
    // Stop wrapping until mobile.
    @media (min-width: 768px) and (max-width: 991px)
    {
        .masthead .navbar-nav > li > a
        {
            font-size: 12px;
        }
    }

// /Added for IE9 compatibility






@media (max-width: $screen-xs-max) {
    .nav-pills > li { min-width: 0; }
    .masthead .block-1 { display: none; }
    .masthead .nav { float: none; }
    .masthead .navbar-brand { padding: 13px 20px; }
    .masthead .navbar-brand .logo { width: 100px; }
    .masthead .block-2:after { top:auto; bottom: 0; }

    .masthead .navbar-nav { margin:0 20px; width:calc(100% - 40px); border-top:1px solid #555;  }
    .masthead .navbar-toggle .icon-bar { background-color: #fff; width: 17px; @include transition(all, 0.3s); }
    .masthead .navbar-toggle[aria-expanded="true"] .icon-bar:first-child {
        @include rotate(45deg);

        top: 6px;
    }
    .masthead .navbar-toggle[aria-expanded="true"] .icon-bar:nth-child(2) {
        @include rotate(135deg);
    }
    .masthead .navbar-toggle[aria-expanded="true"] .icon-bar:last-child {
        display:none;
    }
    .masthead .navbar-nav > li > a{ height:50px; font-size:12px; padding-top:16px; }
    .masthead .navbar-nav > li > a:before{ top:17px;}

    .masthead .navbar-user-account{ float:right; display:inline-block;margin-right:10px; margin-top:5px;  }
    .masthead .navbar-user-account li a { color:#fff; }
    .masthead .navbar-user-account li a:hover { background:none; }
    .masthead .navbar-user-account li a:before{
        content: "";
        display: block;
        height: 20px;
        width: 1px;
        background-color: #333;
        position: absolute;
        top: 10px;
        left:0;
    }
    .masthead .navbar-user-account li:first-child a:before{
        display:none;
    }
    .masthead .navbar-nav > li > a:hover::after
    {
        height: 2px;
    }
    .masthead .navbar-user-account .btn-setting { padding-top:10px; }
    .masthead .navbar-user-account .btn-logout{ padding-right:12px; font-size:12px;}

}
