.membership-number {
  padding-left: 16px;
}


/*wallet-overview*/
.wallet-overview .wallet-item{ height:130px; overflow:hidden; border-top:3px solid #000; overflow:hidden; margin-bottom:10px; @include box-shadow( 0px 1px 2px rgba(0, 0, 0, .2)); } 
.wallet-overview .wallet-item .wallet-item--front,
.wallet-overview .wallet-item .wallet-item--func{ height:130px; background:#fff; float:left; width:100%; @include transition(all, 0.6s);   }
.wallet-overview .wallet-item .wallet-item--func{ background-color:#338598; }
.wallet-overview .wallet-item .wallet-item--front { padding:20px; background-color:#fff; display:block; }
.wallet-overview .wallet-item .wallet-item--front .col-flag{ margin-right:30px;}
.wallet-overview .wallet-item .wallet-item--front .col-content .total{ font-weight:700; font-size:21px; }
.wallet-overview .wallet-item .wallet-item--front .col-content .total strong { font-size:34px; }
.wallet-overview .wallet-item .default-currency{ margin-top:7px; }


.wallet-overview .wallet-item .wallet-item--toggle{ position:absolute; top:50px; right:40px; width:28px; height:28px; background-color:#000; z-index:9;  }
.wallet-overview .wallet-item .wallet-item--toggle i{@include center; color:#fff; font-size:8px; height:7px; width:13px; }
.wallet-overview .wallet-item .wallet-item--func .btn-group{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: distribute;
    width:78%;
    border-right:#00677e 1px solid;
}

.wallet-overview .wallet-item .wallet-item--func .btn:first-child { font-size:16px; }
.wallet-overview .wallet-item .wallet-item--func .btn{ height:130px; border:none; @include border-radius(0); font-weight:bold; display: inline-block; width:33.33%;  }
.wallet-overview .wallet-item .wallet-item--func .btn .icon,
.wallet-overview .wallet-item .wallet-item--func .btn .glyphicon { display:block; margin-bottom:10px; margin-left:auto; margin-right:auto; }

.wallet-overview .wallet-item .wallet-item--func .btn:not(:first-child){border-left:1px solid #00677e !important; margin-left:0 !important;}
.wallet-overview .wallet-item .wallet-item--func .btn--reload{  }
.wallet-overview .wallet-item.show-func .wallet-item--front{ margin-top:-150px;}
.wallet-overview .wallet-item.show-func .wallet-item--toggle i{ @include rotate(180deg); @include transform-origin(24% 24% 0); }



/*ad-top-banner*/
.ad-top-banner{ width:100%; float:left;  }
.ad-top-banner img { width:100%; float:left; }
.ad-container .close{ position:absolute; top:5px; right:15px; }

/*table-exchange-rates*/

.table-exchange-rates .curr{ margin-right:5px; font-weight:700; }
.table-exchange-rates .curr-full { font-size:12px; font-weight:normal;}
.table-exchange-rates .amount {font-weight:700; text-align:right;}
.table-exchange-rates td:last-child{ text-align:right;}
.table-exchange-rates > tbody > tr > th,
.table-exchange-rates > tbody > tr > td { padding-top:15px; padding-bottom:15px; }
.table-exchange-rates > tbody > tr > th{padding-left:15px; padding-right:0; }
.table-exchange-rates > tbody > tr > td{padding-right:15px; padding-left:0;  }

/*member-summary*/
.member-summary{
    .dl-horizontal{ margin-top:10px; margin-bottom:10px; }
    .dl-horizontal dt{ width: 90px; text-align:left; color:#666; font-weight:normal; }
    .dl-horizontal dd{ margin-left: 90px; font-weight:700; }
}

/*page-dashboard*/
.page-dashboard .paper{ padding-bottom:40px; padding-top:140px;  }
.page-dashboard .paper,
.page-dashboard body { background-color:#e6e6e6;}
.page-dashboard body:before { display:none;}
.page-dashboard .footer{ margin-top:0;}
.page-dashboard  .masthead .block-1 .content .welcome-message{ background:transparent;}

.page-dashboard .dashboard-aside{
    left:15px;
    padding-right:20px;
}
.page-dashboard  .btn-load-new-currency{ height:65px; font-size:21px; padding:18px 36px;  }
.page-dashboard .footer{ padding-top:40px;}
.page-dashboard .default-currency .glyphicon{ margin-right:5px; margin-top:1px; }
.page-dashboard h3{ padding-bottom:15px;}

.dashboard-left-col .heading-2 { margin-top:-10px; }



@media (max-width: $screen-xs-max) {
   .page-dashboard .paper{ padding-top:20px; padding-bottom:20px; }
   .dashboard-left-col{ border:none; padding-right:5px; }

   /*wallet-overview*/
   .wallet-overview .wallet-item{ height:auto; margin-bottom:10px; }
   .wallet-overview .wallet-item .wallet-item--front { height:80px; }
   .wallet-overview .wallet-item:first-child .wallet-item--front { height:110px; }
   .wallet-overview .wallet-item .wallet-item--func{ height:0; }
   .wallet-overview .wallet-item.show-func .wallet-item--front{ margin-top:0; }
   .wallet-overview .wallet-item.show-func .wallet-item--func{  height:130px;}
   .wallet-overview .wallet-item .flag{ width:32px; height:32px;}
   .wallet-overview .wallet-item .wallet-item--front .col-content .total{ font-size:16px; }
   .wallet-overview .wallet-item .wallet-item--front .col-content .total strong{ font-size:21px;}
   .wallet-overview .wallet-item .wallet-item--front .col-flag{ margin-right:20px;}
   .wallet-overview .wallet-item .wallet-item--front .curr-title{ margin-bottom:0;}
   .wallet-overview .wallet-item:first-child .wallet-item--toggle{ top:40px; }
   .wallet-overview .wallet-item .wallet-item--toggle{ top:27px; right:20px; }
   .wallet-overview .wallet-item .wallet-item--func .btn-group{ width:100%; border-right:none; }

    /*member-summary*/
    .member-summary{
        .dl-horizontal { margin-top:10px; }
        .dl-horizontal dd{ margin-top:-18px;  }
        .dl-horizontal dd:after{ content:""; display:table;   }
        .member-summary__front .content{ padding-right:40px; }
        .member-summary__toggle{ display:inline-block; position:absolute; right:0; top:18px; padding:0;}
        .member-summary__details{ display:none; }

        &.show-summary {
            .member-summary__details{ display:block; }
            .member-summary__toggle i{  @include rotate(180deg); @include transform-origin(50% 30% 0); }
        }
    }

    /*page-dashboard*/
    .page-dashboard .dashboard-aside{
        left:0;
        padding-right:5px;
    }
    .page-dashboard .footer{ padding-top:0;}
    .page-dashboard .btn-load-new-currency{ height:40px; padding:8px 16px;  font-size:16px; }

}


@media (max-width: 520px) {
    .wallet-overview .wallet-item.show-func .wallet-item--func{ height:150px; }
    .wallet-overview .wallet-item .wallet-item--func .btn-group{ display:block;  }
    .wallet-overview .wallet-item .wallet-item--func .btn{ width:100% !important; height:50px; border-top:1px solid $brand-primary; text-align:left; padding-left:25px;  }
    .wallet-overview .wallet-item .wallet-item--func .btn .icon,
    .wallet-overview .wallet-item .wallet-item--func .btn .glyphicon{ display:inline-block; margin-right:30px; margin-bottom:0;   }
    .wallet-overview .wallet-item .wallet-item--func .btn br{ display:none; }

}
