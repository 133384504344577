.page-error body:before
{
    height: 0;
    padding-bottom: 57%;
    // height: 1104px;
    background-image: url("../../public/images/404.jpg");
    background-size: cover;
}
.page-error .paper
{
    background: transparent;
}
.section-error-container
{
    height: 0;
    padding-bottom: 57%;
}
.section-error
{
    padding: 125px 0;
}
.section-error h1
{
    padding-bottom: 30px;
    margin-bottom: 30px;

    line-height: 1.1;
    font-size: 86px;
    font-weight: 300;
    text-align: center;
    text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.4);
    background: url("../../public/images/divider-white.png") no-repeat center bottom;
    color: #fff;
}
.section-error p
{
    margin: 30px 0;

    font-size: 18px;
    text-align: center;
    color: #fff;
}
.section-error a[aria-role="button"]
{
    @include c-brochure-button-solid-large-rounded;
    @include t-brochure-button-solid-brand;

    &:hover,
    &:focus,
    &:active
    {
        @include t-brochure-button-solid-darker;
    }
}

.page-404﻿
{
    // 404 specific styles here.
}

@media screen and (max-width: 1046px)
{
    .section-error
    {
        padding: 40px 0;
    }
    .section-error h1
    {
        padding-bottom: 19px;
        margin-bottom: 16px;

        font-size: 40px;
    }
    .section-error p
    {
        margin: 16px 0;
    }
}
@media screen and (max-width: 767px)
{
    .section-error
    {
        padding: 10px 0;
    }
    .section-error a[aria-role="button"]
    {
        font-size: 14px;
    }
    .section-error-container
    {
        height: 280px;
        padding-bottom: 0;
    }
}
@media screen and (max-width: 479px)
{
    .page-error body:before
    {
        height: 280px;
        padding-bottom: 0;
    }

}
