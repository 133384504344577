/*statement-list*/
.statement-list{ list-style:none; }
.statement-list .statement-item{ padding:18px 0; border-top:1px solid #888; float:left; width:100%; }
.statement-list .statement-item:hover{ background-color:#f9f9f9; }
.statement-list .statement-item:last-child{ border-bottom:1px solid #888; }
.statement-list .statement-item .col-icon { width:60px; padding-left:25px;  }
.statement-list .statement-item .col-date,
.statement-list .statement-item .col-func { width:calc(50% - 30px);  }
.statement-list .statement-item .col-date{ padding-left:10px; }
.statement-list .statement-item .col-func { text-align:right; /*display:none;*/ padding-right:20px; }
.statement-list .statement-item .col-func a { margin-left:20px; color:#13100d; font-weight:bold; }

@media (min-width: $screen-sm-min) {
    .statement-list .statement-item:hover .col-func { display:block; }
}

@media (max-width: $screen-xs-max) {
   .statement-list .statement-item{ padding:15px 0;  }
   .statement-list .statement-item:hover {cursor:pointer; }
   .statement-list .statement-item .col-icon{ width:50px; padding-left:15px; }
   .statement-list .statement-item .col-date{ padding-left:0; width:calc(100% - 50px)}
   .statement-list .statement-item .col-func {
     width:100%;
     padding-left:50px;
     display:none;
   }


   .statement-list .statement-item.show-func {
     .col-date {
       /*width:calc(40% - 50px);*/
     }
     .col-func {
       /*width:calc(60% - 20px);*/
       margin-left: 30px;
       display: block;
       padding: 16px 0 0 0;
       text-align: left;


       a {
         display: inline-block;
       }

     }

   }
   /*
   .statement-list .statement-item .col-func a { float:left; margin-left:0; margin-right:20px; margin-top:10px; }
   */
   /*.statement-list .statement-item.show-func .col-func{ display:block; }*/
}
