#masthead-half-bg {
  padding-top: 40px;
  @media (max-width: $screen-xs-max) {
    padding-top: 10px;
  }
}

.masthead--activation { height:70px; padding-top:20px; }
.masthead--activation .page-name{color: #fff; padding-top: 25px;font-size: 16px; font-weight: 700; display: inline-block; height:70px;   }

/*activation-1*/
.activation-1 .home-addresschange-btn{ right:0; top:16px; position:absolute; }

.activation-3 .aa-logo{ display:block; width:50px; height:50px; float:right; position:absolute; top:0; right:0; }
.activation-3-a .heading { max-width:500px;  }


.card-activation-bg:before {
  background: url(../images/activate-background-image.jpg) no-repeat 100% 100%;
  background-size:cover;
  height: 806px;
}

.dimmed {
  position: relative;
}

.dimmed:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  right: 0;
  background: rgba(0, 0, 0, 0.6);

  @media (max-width: $screen-xs-max) {
    left: 0;
  }
}

#activation-header-main {
  /*position: absolute;
  top: 0;
  left: 0;
  right: 0;

  height: 750px;
  width: 100%;
  */

  .inner-opaque {
    max-width: 500px;
    padding: 60px 0 60px 60px;

    @media (max-width: $screen-xs-max) {
      padding: 20px 10px;
      max-width: auto;
    }
  }
}

.activate-card-img {
  max-height: 64px;
  max-width: 100%;
}

.opaque-bg {
  h1 {
    text-shadow: 2px 2px black;
    font-size: 56px;

    @media (max-width: $screen-xs-max) {
      font-size: 30px;
    }
  }

  h3 {
    font-weight: 500;
  }
  background-color:rgba(0, 0, 0, 0.6);
}

.activation-container {
  h1, h2, h3, h4, h5, p, span, label, a {
    color: white !important;
  }

  label {
    font-size: 14px;
  }

  .form-group.label-static label.control-label, .form-group.label-floating.is-focused label.control-label, .form-group.label-floating:not(.is-empty) label.control-label {
    font-size: 14px;
  }

  .form-control, .form-group .form-control {
    background-image: linear-gradient(#00677e, #00677e), linear-gradient(#fff, #fff);
      color: #fff;
  }


  .cvn-box {
    background: none;
    margin-right: 0;
    border-bottom: 1px solid white;
    color: white;
    font-size: 16px;
    width: 24%;

    @media (max-width: $screen-xs-max) {
      width: calc(23% + 2px);
    }
  }


}

.activation-section {
  margin-top: 60px;
  margin-bottom: 60px;

  @media (max-width: $screen-xs-max) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.white-bg {
  background-color: white;
}


@media (max-width: $screen-xs-max) {

    .masthead--activation { height:60px; padding-top:0; }
    .masthead--activation .page-name{ font-size:12px; margin-top:0; height:auto; padding-top: 22px; }
    .masthead--activation .block-2{ height:60px;}
    .masthead--activation .block-2:after{ top:50px;}
    .masthead--activation .navbar-brand .logo{ margin-top:5px;}

    /*activation-1*/
    .activation-1 .home-addresschange-btn{ background:none; text-decoration:underline; color:#000; border:none; @include box-shadow( 0 0 0 rgba(0, 0, 0, 0)); padding:0;  }
    .activation-2 .password-rule{ padding-left:0;}
    .activation-3-a .heading { padding-right:50px; max-width:430px; }

}
